import React from "react";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
  overlay: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: "0",
    left: "0",
    zIndex: "99999",
    backgroundColor: "rgba(0,0,0,0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: `$opacityTransition 200ms linear`,
  },
  popup__inner: {
    position: "relative",
    maxWidth: "500px",
    padding: "1rem 2rem 1rem 1rem",
    backgroundColor: "#eeeeee",
    margin: "0 2rem",
    color: "black",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    borderRadius: "4px",
    fontSize: "1.3rem",
    animation: `$slideInFromTop 500ms cubic-bezier(0.21, 1.07, 0.85, 1.04)`,
  },
  popup__toggle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > p": {
      margin: 0,
      fontSize: "1rem",
    },
  },
  closeBtn: {
    position: "absolute",
    top: "20px",
    right: "15px",
  },
  closeBtn__inner: {
    width: "100%",
    cursor: "pointer",
    minHeight: "15px",
    "&::before, &:after": {
      content: "''",
      width: "20px",
      height: "2px",
      display: "block",
      backgroundColor: "black",
    },
    "&::before": {
      transform: "translateY(5px) rotate(-45deg)",
    },
    "&:after": {
      transform: "translateY(3px) rotate(45deg)",
    },
  },
  "@keyframes slideInFromTop": {
    from: {
      transform: "translateY(-50%)",
      opacity: "0",
    },
    to: {
      transform: "translateY(0)",
      opacity: "1",
    },
  },
  "@keyframes opacityTransition": {
    from: {
      opacity: "0",
    },
    to: {
      opacity: "1",
    },
  },
});
function EmailDialog({ displayEmailToggle, content, hidePopup, emailToggle }) {
  const classes = useStyles();
  return (
    <>
      {displayEmailToggle && (
        <div className={classes.overlay} onClick={(e) => hidePopup(e)}>
          <div className={classes.popup__inner}>
            <div className={classes.popup__main}>
              <p>{content.text}</p>
            </div>
            <div className={classes.popup__toggle}>
              <p>{content.text2}</p>
              {emailToggle}
            </div>
            <div className={classes.closeBtn} onClick={() => hidePopup()}>
              <div className={classes.closeBtn__inner}></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EmailDialog;
