import React from "react";
import "./styling.css";
function PageTitle({ title }) {
  return (
    <div className='pageTitle _container'>
      <h2>{title}</h2>
    </div>
  );
}

export default PageTitle;
