import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import "./styles.css";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import axios from "axios";
import querystring from "querystring";

class Main extends Component {
  constructor() {
    super();
    this.state = {
      dialogError: "",
      fade: "out",
      emailfield: "Hide",
      emailButton: "Hide",
      loginField: "Show",
      loginButton: "Show",
      emailNotifications: true,
      pushNotifications: false,
    };
  }

  componentDidMount() {
    this.languageRouter();

    let params = querystring.parse(this.props.location.search);

    if (window.location.href.includes("?TNC")) {
      localStorage.clear();
      this.handleSubmitFromUrl(params["?TNC"]);
    } else {
      localStorage.clear();
      // axios
      //   .post(
      //     `${process.env.REACT_APP_BASE_URL}/token`,
      //     querystring.stringify({
      //       TNC: params["?TNC"],
      //       grant_type: "password",
      //     })
      //   )

      //   .then((response) => {
      //     window.localStorage.setItem(
      //       "AccessToken",
      //       "Bearer " + response.data.access_token
      //     );
      //     this.props.history.push("welcome");
      //   })
      //   .catch((error) => {
      //     console.log("There is an error: ", error.message);
      //     if (error.message === "Request failed with status code 400") {
      //       this.setState({
      //         dialogError:
      //           "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.  ",
      //         fade: "in",
      //       });
      //     } else {
      //       this.setState({
      //         dialogError: "" + error.message,
      //         fade: "in",
      //       });
      //     }
      //   });
    }
    if (localStorage.AccessToken === undefined) {
      localStorage.clear();
    } else {
      // this.props.history.push(window.location.pathname + "/home");
    }
    // if (params.Lastname === undefined) {
    //   console.log("Params NULL");
    // } else {
    //   console.log("Params NOT NULL");
    //   console.log(params.Pin);
    //   console.log(params.Lastname);

    //   axios
    //     .post(
    //       `${process.env.REACT_APP_BASE_URL}/token`,
    //       querystring.stringify({
    //         Pin: params.Pin,
    //         Lastname: params.Lastname,
    //         grant_type: "password",
    //       })
    //     )

    //     .then((response) => {
    //       window.localStorage.setItem(
    //         "AccessToken",
    //         "Bearer " + response.data.access_token
    //       );
    //       this.props.history.push(window.location.pathname + "/welcome");
    //     })
    //     .catch((error) => {
    //       console.log("There is an error: ", error.message);
    //       if (error.message === "Request failed with status code 400") {
    //         this.setState({
    //           dialogError:
    //             "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.  ",
    //           fade: "in",
    //         });
    //       } else {
    //         this.setState({
    //           dialogError: "" + error.message,
    //           fade: "in",
    //         });
    //       }
    //     });
    // }
  }
  //Check if user is locked
  checkLockSource = (value, language) => {
    if (value) {
      localStorage.clear();
      this.props.history.push(language + "?locked");
      window.location.pathname === "/fr"
        ? this.setState({
            dialogError:
              "Le questionnaire a déjà été finalisé, il n'est plus possible de le modifier.",
            fade: "in",
          })
        : window.location.pathname === "/it"
        ? this.setState({
            dialogError:
              "L'inchiesta è già stata conclusa. Non sono più consentite modifiche.",
            fade: "in",
          })
        : window.location.pathname === "/en"
        ? this.setState({
            dialogError:
              "You have already completed the survey. Alterations are therefore no longer possible.",
            fade: "in",
          })
        : this.setState({
            dialogError:
              "Sie haben die Umfrage bereits abgeschlossen. Änderungen sind daher nicht mehr möglich.",
            fade: "in",
          });
      setTimeout(() => {
        this.setState({ fade: "out" });
      }, 2500);
    } else {
      this.props.history.push(language + "/home");
    }
  };
  registerHandler = (a, b, c) => {
    window.localStorage.setItem("firstTimeLogin", "true");
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/activate`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("Email", a);
    urlencoded.append("EmailRepeat", a);
    urlencoded.append("EnableDailyNotifications", b);
    urlencoded.append("EnablePushNotificationReminder", c);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("Result of the user activation: ", result);
        this.customHandleSubmit(this.state.userProfile.TNC);
      })
      .catch((error) => console.log("error", error));
  };

  languageRouter = () => {
    if (window.location.pathname === "/") {
      this.props.history.push("/de");
    }
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(
        //   "USER AUTH REQUEST-RESULT AFTER TOKEN REQUEST WAS SUCCESSFUL ",
        //   result
        // );
        this.setState(
          {
            userProfile: result.Preload,
            resultsData: result,
          },
          () => {
            // window.localStorage.setItem("firstTimeLogin", "true");
          }
        );
        if (result.Preload.Email === null || result.Preload.Email.length < 1) {
          this.registerEmailHandler();
        } else if (result.Preload.Email.length > 1) {
          if (result.IsRegistered === false) {
            this.registerHandler(result.Preload.Email, true, true);
          } else {
            let language =
              result.Preload.Language === 1
                ? "/de"
                : result.Preload.Language === 2
                ? "/fr"
                : result.Preload.Language === 3
                ? "/it"
                : result.Preload.Language === 4
                ? "/en"
                : "/de";

            //Check if the user has closed his survery, if not redirect /home
            this.checkLockSource(result.Preload.LockSource, language);

            // this.props.history.push(window.location.pathname + "/home");
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  registerEmailHandler = () => {
    this.setState({
      emailfield: "Show",
      emailButton: "Show",
      loginField: "Hide",
      loginButton: "Hide",
    });
  };

  customHandleSubmit = (param) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: param,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem(
          "AccessToken",
          "Bearer " + response.data.access_token
        );
        this.handleUser();
        // this.props.history.push(window.location.pathname + '/welcome')
      })
      .catch((error) => {
        console.log("There is an error: ", error.message);
        if (error.message === "Request failed with status code 400") {
          window.location.pathname === "/fr"
            ? this.setState({
                dialogError:
                  "Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.",
                fade: "in",
              })
            : window.location.pathname === "/it"
            ? this.setState({
                dialogError:
                  "Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.",
                fade: "in",
              })
            : window.location.pathname === "/en"
            ? this.setState({
                dialogError:
                  "The participation code is invalid. Please check your input and try again.",
                fade: "in",
              })
            : this.setState({
                dialogError:
                  "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
                fade: "in",
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
        setTimeout(() => {
          this.setState({ fade: "out" });
        }, 2500);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: event.target[0].value,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem(
          "AccessToken",
          "Bearer " + response.data.access_token
        );
        this.handleUser();
        // this.props.history.push(window.location.pathname + '/welcome')
      })
      .catch((error) => {
        console.log("There is an error: ", error.message);
        if (error.message === "Request failed with status code 400") {
          window.location.pathname === "/fr"
            ? this.setState({
                dialogError:
                  "Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.",
                fade: "in",
              })
            : window.location.pathname === "/it"
            ? this.setState({
                dialogError:
                  "Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.",
                fade: "in",
              })
            : window.location.pathname === "/en"
            ? this.setState({
                dialogError:
                  "The participation code is invalid. Please check your input and try again.",
                fade: "in",
              })
            : this.setState({
                dialogError:
                  "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut. ",
                fade: "in",
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
        setTimeout(() => {
          this.setState({ fade: "out" });
        }, 2500);
      });
  };
  handleSubmitFromUrl = (tnc) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: tnc,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem(
          "AccessToken",
          "Bearer " + response.data.access_token
        );
        this.handleUser();
        // this.props.history.push(window.location.pathname + '/welcome')
      })
      .catch((error) => {
        console.log("There is an error: ", error.message);
        if (error.message === "Request failed with status code 400") {
          window.location.pathname === "/fr"
            ? this.setState({
                dialogError:
                  "Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.",
                fade: "in",
              })
            : window.location.pathname === "/it"
            ? this.setState({
                dialogError:
                  "Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.",
                fade: "in",
              })
            : window.location.pathname === "/en"
            ? this.setState({
                dialogError:
                  "The participation code is invalid. Please check your input and try again.",
                fade: "in",
              })
            : this.setState({
                dialogError:
                  "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut. ",
                fade: "in",
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
        setTimeout(() => {
          this.setState({ fade: "out" });
        }, 2500);
      });
  };
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <div className='loginScreen'>
          <Card raised={true} className={"errorDialog fade-" + this.state.fade}>
            <div className='errorContent'>
              <p>{this.state.dialogError}</p>
            </div>
          </Card>
          <div className='login_screen'>
            <div className='login_screen_inner'>
              {/* <div className='specificLink'>
                {window.location.pathname === "/fr" ? (
                  <p>
                    Si votre semaine de relevé se situe entre le 05/04/2021 et
                    le 13/06/2021, veuillez cliquer{" "}
                    <a href='https://ga21.allianceswisspass.ch/fr'>ici</a> pour
                    vous connecter.
                  </p>
                ) : window.location.pathname === "/it" ? (
                  <p>
                    Se la tua settimana di rilevazione è compresa tra il
                    05/04/2021 e il 13/06/2021, clicca{" "}
                    <a href='https://ga21.allianceswisspass.ch/it'>qui</a> per
                    effettuare il login.
                  </p>
                ) : window.location.pathname === "/en" ? (
                  <p>
                    If your survey week is between 05.04.2021 and 13.06.2021,
                    please click{" "}
                    <a href='https://ga21.allianceswisspass.ch/en'>here</a> to
                    log in.
                  </p>
                ) : (
                  <p>
                    Falls Ihre Erhebungswoche im Zeitraum zwischen dem
                    05.04.2021 und dem 13.06.2021 liegt, dann klicken Sie zum
                    Login bitte{" "}
                    <a href='https://ga21.allianceswisspass.ch/de'>hier.</a>
                  </p>
                )}
              </div> */}
              <div className='loginFields'>
                {window.location.pathname === "/fr" ? (
                  <p>Veuillez vous connecter pour saisir vos trajets.</p>
                ) : window.location.pathname === "/it" ? (
                  <p>Acceda ora per registrare i suoi viaggi.</p>
                ) : window.location.pathname === "/en" ? (
                  <p>Please log in to enter your journeys.</p>
                ) : (
                  <p>
                    Bitte melden Sie sich jetzt an, um Ihre Reisen zu erfassen.
                  </p>
                )}
                <form onSubmit={this.handleSubmit}>
                  <div
                    id={"loginField" + this.state.loginField}
                    className='field'
                  >
                    {window.location.pathname === "/fr" ? (
                      <TextField
                        fullWidth={true}
                        id='pin'
                        name='pin'
                        label='TNC'
                        variant='filled'
                      />
                    ) : window.location.pathname === "/it" ? (
                      <TextField
                        fullWidth={true}
                        id='pin'
                        name='pin'
                        label='TNC'
                        variant='filled'
                      />
                    ) : window.location.pathname === "/en" ? (
                      <TextField
                        fullWidth={true}
                        id='pin'
                        name='pin'
                        label='TNC'
                        variant='filled'
                      />
                    ) : (
                      <TextField
                        fullWidth={true}
                        id='pin'
                        name='pin'
                        label='Teilnahmecode'
                        variant='filled'
                      />
                    )}
                  </div>
                  <div
                    id={"emailField" + this.state.emailfield}
                    className='emailField'
                  >
                    {window.location.pathname === "/fr" ? (
                      <TextField
                        fullWidth={true}
                        id='pin'
                        name='email'
                        label='Veuillez indiquer votre adresse e-mail.'
                        variant='filled'
                        onChange={this.onChangeHandler}
                      />
                    ) : window.location.pathname === "/it" ? (
                      <TextField
                        fullWidth={true}
                        id='email'
                        name='email'
                        label='Immetta il suo indirizzo e-mail.'
                        variant='filled'
                        onChange={this.onChangeHandler}
                      />
                    ) : window.location.pathname === "/en" ? (
                      <TextField
                        fullWidth={true}
                        id='email'
                        name='email'
                        label='Please enter your email address.'
                        variant='filled'
                        onChange={this.onChangeHandler}
                      />
                    ) : (
                      <TextField
                        fullWidth={true}
                        id='email'
                        name='email'
                        label='Bitte geben Sie Ihre E-Mail-Adresse ein'
                        variant='filled'
                        onChange={this.onChangeHandler}
                      />
                    )}
                  </div>
                  {window.location.pathname === "/fr" ? (
                    <Link
                      className='faqLink'
                      to='fr/information'
                      target='_blank'
                    >
                      FAQ
                    </Link>
                  ) : window.location.pathname === "/it" ? (
                    <Link
                      className='faqLink'
                      to='it/information'
                      target='_blank'
                    >
                      FAQ
                    </Link>
                  ) : window.location.pathname === "/en" ? (
                    <Link
                      className='faqLink'
                      to='en/information'
                      target='_blank'
                    >
                      FAQ
                    </Link>
                  ) : (
                    <Link
                      className='faqLink'
                      to='de/information'
                      target='_blank'
                    >
                      FAQ
                    </Link>
                  )}
                  {/*
                window.location.pathname === '/fr' ?
                  <Link className="forgotPinLink" to="fr/forgot/pin">NIP oublié?</Link>
                : window.location.pathname === '/it' ?
                  <Link className="forgotPinLink" to="it/forgot/pin">PIN dimenticato?</Link>
                : <Link className="forgotPinLink" to="de/forgot/pin">PIN vergessen?</Link>
              */}
                  <Container
                    id={"loginButton" + this.state.loginButton}
                    className='loginButtonSection'
                  >
                    {window.location.pathname === "/fr" ? (
                      <Button
                        type='submit'
                        className='loginButton'
                        variant='contained'
                      >
                        Connexion
                      </Button>
                    ) : window.location.pathname === "/it" ? (
                      <Button
                        type='submit'
                        className='loginButton'
                        variant='contained'
                      >
                        Login
                      </Button>
                    ) : window.location.pathname === "/en" ? (
                      <Button
                        type='submit'
                        className='loginButton'
                        variant='contained'
                      >
                        Login
                      </Button>
                    ) : (
                      <Button
                        type='submit'
                        className='loginButton'
                        variant='contained'
                      >
                        Login
                      </Button>
                    )}
                  </Container>
                  <Container
                    id={"emailButton" + this.state.emailButton}
                    className='loginButtonSection'
                  >
                    {window.location.pathname === "/fr" ? (
                      <Button
                        onClick={() =>
                          this.registerHandler(
                            this.state.email,
                            this.state.emailNotifications,
                            this.state.pushNotifications
                          )
                        }
                        className='loginButton'
                        variant='contained'
                      >
                        Inscription
                      </Button>
                    ) : window.location.pathname === "/it" ? (
                      <Button
                        onClick={() =>
                          this.registerHandler(
                            this.state.email,
                            this.state.emailNotifications,
                            this.state.pushNotifications
                          )
                        }
                        className='loginButton'
                        variant='contained'
                      >
                        Registrazione
                      </Button>
                    ) : window.location.pathname === "/en" ? (
                      <Button
                        onClick={() =>
                          this.registerHandler(
                            this.state.email,
                            this.state.emailNotifications,
                            this.state.pushNotifications
                          )
                        }
                        className='loginButton'
                        variant='contained'
                      >
                        Continue
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          this.registerHandler(
                            this.state.email,
                            this.state.emailNotifications,
                            this.state.pushNotifications
                          )
                        }
                        className='loginButton'
                        variant='contained'
                      >
                        Weiter
                      </Button>
                    )}
                  </Container>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
