import * as moment from "moment";

export function formatDates(objArr) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return objArr.map((date) => {
    return date.toLocaleString("de-DE", options);
  });
}
export function formatSingleDate(date) {
  moment.locale();
  // const options = {
  //   weekday: "long",
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric",
  //   timeZone: "UTC",
  // };
  return moment(date).format("dddd, DD.MM.YYYY");

  // return date.toLocaleString("de-DE", options);
}
