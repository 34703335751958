import React from "react";
import { makeStyles } from "@material-ui/styles";
// import BottomNavigation from "@material-ui/core/BottomNavigation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "60px",
    position: "fixed",
    zIndex: "1",
    bottom: "0",
    backgroundColor: "rgb(246, 246, 246)",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between",
    boxShadow: "0px 0px 9px 3px rgba(41,41,41,.25)",
  },
  wrapper: ({ flexJustify }) => ({
    maxWidth: "1200px",
    margin: "0 auto",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: flexJustify ? `${flexJustify}` : "space-between",
  }),
  nextBtn: {
    height: "100%",
    padding: "0 1rem",
    backgroundColor: "#02b05a",
    borderRadius: 0,
    color: "#fff",
    "&:hover": {
      "& $nextArrow": {
        transform: "translateX(5px)",
      },
      backgroundColor: "#1fbc6f",
    },
  },
  backBtn: {
    height: "100%",
    padding: "0 1rem",
    borderRadius: 0,
    backgroundColor: "rgb(238, 238, 238)",
    "&$disabled": {
      backgroundColor: "rgb(238, 238, 238)",
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  btnPlaceholder: {
    height: "100%",
    marginRight: "clamp(2rem, 10vw, 8rem)",
  },
  nextArrow: {
    transition: "transform 200ms linear",
  },
  backArrow: {
    color: "black",
  },
  backBtnTypo: {
    textTransform: "none",
    textAlign: "left",
    fontSize: "clamp(12px, 3vw, 1rem)",
  },
  BtnTypo: {
    fontSize: "clamp(12px, 3vw, 1rem)",
  },
  disabled: {},
});

// BottomNav components takes in as props the function which the 'next arrow button' has to run when clicked,
// the param to be passed to this function
// the state to check if the page should have the back button displayed,
// the function to go back if neccessary

function BottomNav({
  handleBtn,
  backButtonHandler,
  backBtnState,
  btnText,
  btnParam,
  nxtBtnColor,
  nxtTxtColor,
  hideBbtnArror,
  backBtnText,
  flexJustify,
}) {
  const handleForwardBtn = () => {
    if (handleBtn) {
      if (btnParam === null) {
        handleBtn();
      } else if (typeof btnParam === "string") {
        handleBtn(btnParam === "true" ? true : btnParam);
      } else if (btnParam[0]?.formatedDate) {
        handleBtn(btnParam);
      } else {
        handleBtn(btnParam.name, btnParam.code);
      }
    }
  };
  const classes = useStyles({ flexJustify });
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.btnPlaceholder}>
          {backBtnState && (
            <Button
              disabled={hideBbtnArror}
              classes={{
                root: classes.backBtn,
                disabled: classes.disabled,
              }}
              style={{ backgroundColor: hideBbtnArror && "rgb(246, 246, 246)" }}
              // className={classes.backBtn}
              onClick={() => {
                backButtonHandler();
              }}
            >
              {!hideBbtnArror && (
                <ArrowBackIosIcon className={classes.backArrow} />
              )}

              <Typography className={classes.backBtnTypo} variand='body1'>
                {backBtnText}
              </Typography>
            </Button>
          )}
        </div>
        {btnParam !== "hide" ? (
          <Button
            onClick={handleForwardBtn}
            className={classes.nextBtn}
            style={{ backgroundColor: nxtBtnColor, color: nxtTxtColor }}
          >
            <Typography variant='body1' className={classes.BtnTypo}>
              {btnText}
            </Typography>
            <ArrowForwardIosIcon className={classes.nextArrow} />
          </Button>
        ) : null}
      </div>
    </div>
  );
}
BottomNav.defaultProps = {
  btnParam: null,
  btnState: false,
  nxtBtnColor: "#02b05a",
  nxtTxtColor: "#fff",
  hideBbtnArror: false,
  backBtnText: window.location.pathname.includes("/fr")
    ? "RETOUR"
    : window.location.pathname.includes("/it")
    ? "INDIETRO"
    : window.location.pathname.includes("/en")
    ? "BACK"
    : "ZURÜCK",
  // handleBtn: null,
};

export default BottomNav;
