import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './styles.css';
import AppBar from '../../Shared/AppBar';
import PageTitle from '../../Shared/PageTitle';
import MainHomeScreen from './Main';
import StartortScreen from './Startort';
import ZielortScreen from './Zielort';
import UmsteigeorteScreen from './Umsteigeorte';
import UpdateJourney from './UpdateJourney';
import FinishJourney from './FinishJourney';
import MultipleTrips from './MultipleTrips';
import axios from 'axios';
import * as moment from 'moment';
import querystring from 'querystring';
import './styles.css';
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'Meine Reiseübersicht',
      from: '',
      back: false,
      prev: '',
      value: 'home',
      loading: false,
      date: null,
      startPointName: null,
      startPointCode: null,
      initExtraStops: [],
      toSubmitStops: [],
      ExtraStops: [],
      endPointName: null,
      endPointCode: null,
      connectingPointScreen: null,
      userProfile: [],
      startTravel: null,
      endTravel: null,
      editTripId: null,
      tripsData: [],
      connectingPointIndex: 0,
      path: window.location.pathname.substring(0, 3),
      updatedALL: false,
      screenWidth: null,
    };
  }

  componentDidMount() {
    this.isUserAuthenticated();
    this.userProfileHandler();
    this.handleUser();
    this.backButtonChecker();
    this.handleTrips();
    if (window.location.pathname === '/fr/home') {
      this.setState({
        page: 'Mes trajets',
      });
    } else if (window.location.pathname === '/it/home') {
      this.setState({
        page: 'La mia panoramica dei viaggi',
      });
    } else if (window.location.pathname === '/en/home') {
      this.setState({
        page: 'Travel diary',
      });
    }
    // setTimeout(() => {
    //   this.reTokenizeUser();
    // }, 2500);
    this.finishLinkHandler();

    //Update screen width upon resize
    window.addEventListener('resize', this.updateWindowDimensions());
  }
  componentWillUnmount() {
    //Remove the event listener 'resize'
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };
  backButtonChecker = () => {
    if (
      this.state.page === 'Abschluss' ||
      this.state.page === 'Conclusion' ||
      this.state.page === 'Conclusione' ||
      this.state.page === 'Completion' ||
      //Contest page name
      this.state.page === 'Wettbewerb' ||
      this.state.page === 'Concurrence' ||
      this.state.page === 'Concorrenza' ||
      this.state.page === 'Competition' ||
      this.state.page === 'Reisedatum auswählen' ||
      this.state.page === 'Sélectionner la date du trajet' ||
      this.state.page === 'Selezionare data di viaggio' ||
      this.state.page === 'Startort eingeben' ||
      this.state.page === "Indiquer l'arrêt de départ" ||
      this.state.page === 'Immettere luogo di partenza' ||
      this.state.page === 'Departure point' ||
      this.state.page === 'Zielort eingeben' ||
      this.state.page === "Indiquer l'arrêt de destination" ||
      this.state.page === 'Immettere destinazione' ||
      this.state.page === 'Destination' ||
      this.state.page === 'Umsteigeorte hinzufügen' ||
      this.state.page === 'Ajouter des arrêts de correspondance' ||
      this.state.page === 'Aggiungere punti di cambio' ||
      this.state.page === 'Places where you changed connections' ||
      this.state.page === 'Reise bearbeiten' ||
      this.state.page === 'Modifica viaggio' ||
      this.state.page === 'Modifier le voyage' ||
      this.state.page === 'Edit trip' ||
      this.state.page === 'Choisissez un moyen de transport' ||
      this.state.page === 'Scegli il mezzo di trasporto' ||
      this.state.page === 'Transportmittel wählen' ||
      this.state.page === 'Types of transport' ||
      this.state.page === 'Altro giorno / Ritorno' ||
      this.state.page === 'Anderer Tag / Rückreise' ||
      this.state.page === 'Autre jour / Retour' ||
      this.state.page === 'Other day / Return trip'
    ) {
      this.setState({
        back: true,
      });
    } else {
      this.setState({ back: false });
    }
  };

  finishLinkHandler = () => {
    if (window.location.search === '?Abschluss') {
      this.setState({ page: 'Abschluss' });
    } else if (window.location.search === '?Conclusion') {
      this.setState({ page: 'Conclusion' });
    } else if (window.location.search === '?Conclusione') {
      this.setState({ page: 'Conclusione' });
    } else if (window.location.search === '?Completion') {
      this.setState({ page: 'Completion' });
    }
  };

  //Set contest page name
  contestPage = () => {
    window.location.pathname.includes('/fr')
      ? this.setState({ page: 'Concurrence' })
      : window.location.pathname.includes('/it')
      ? this.setState({ page: 'Concorrenza' })
      : window.location.pathname.includes('/en')
      ? this.setState({ page: 'Competition' })
      : this.setState({ page: 'Wettbewerb' });
  };

  // reTokenizeUser = () => {
  //   if (this.state.userProfile.Email.includes("@")) {
  //     // this.props.history.push('/?A=' + this.state.userProfile.Pin + this.state.userProfile.ProjectCode + this.state.userProfile.Pin)
  //   } else {
  //     const url = window.location.host.includes("ag.allianceswisspass")
  //       ? "/fr/"
  //       : window.location.host.includes("agquestionario")
  //       ? "/it/"
  //       : "/de/";
  //     const link =
  //       url +
  //       "?A=" +
  //       this.state.userProfile.Pin +
  //       this.state.userProfile.ProjectCode +
  //       this.state.userProfile.Pin;
  //     console.log("LINK: ", link);
  //     this.props.history.push(link);
  //  }
  // };

  registerHandler = (a, b, c) => {
    window.localStorage.setItem('firstTimeLogin', 'true');
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/activate`;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', window.localStorage.AccessToken);
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    var urlencoded = new URLSearchParams();
    urlencoded.append('Email', a);
    urlencoded.append('EmailRepeat', a);
    urlencoded.append('EnableDailyNotifications', b);
    urlencoded.append('EnablePushNotificationReminder', c);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.customHandleSubmit(this.state.userProfile.TNC);
      })
      .catch((error) => console.log('error', error));
  };
  customHandleSubmit = (param) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: param,
          grant_type: 'password',
        })
      )

      .then((response) => {
        window.localStorage.setItem('AccessToken', 'Bearer ' + response.data.access_token);
        this.handleUser();
        // this.props.history.push(window.location.pathname + '/welcome')
      })
      .catch((error) => {
        console.log('There is an error: ', error.message);
        if (error.message === 'Request failed with status code 400') {
          window.location.pathname === '/fr'
            ? this.setState({
                dialogError:
                  "Le code de participation n'est pas valable. Veuillez vérifier votre saisie  puis réessayer.",
                fade: 'in',
              })
            : window.location.pathname === '/it'
            ? this.setState({
                dialogError:
                  "Il codice di partecipazione non è valido; verifichi l'ingresso e riprovi.",
                fade: 'in',
              })
            : window.location.pathname === '/en'
            ? this.setState({
                dialogError:
                  'The participation code is invalid. Please check your input and try again. ',
                fade: 'in',
              })
            : this.setState({
                dialogError:
                  'Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.  ',
                fade: 'in',
              });
        } else {
          this.setState({
            dialogError: '' + error.message,
            fade: 'in',
          });
        }
      });
  };

  userProfileHandler = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', window.localStorage.AccessToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            userInfo: result,
          },
          () => {
            if (result.Preload.Email.length > 1) {
              if (this.state.userInfo.IsRegistered === false) {
                this.registerHandler(result.Preload.Email, true, true);
              }
            } else {
              this.props.history.push('/register');
            }
          }
        );
      })
      .catch((error) => console.log('error', error));
  };

  handleTrips = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Journeys/Journeys`;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', window.localStorage.AccessToken);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          tripsData: result,
        });
      })
      .catch((error) => console.log('error', error));
  };

  backButtonHandler = () => {
    //BACK FROM COMPLETING THE JOURNEY
    if (this.state.page === 'Abschluss' && this.state.connectingPointScreen === null) {
      this.setState({
        page: 'Meine Reiseübersicht',
      });
    }
    if (this.state.page === 'Conclusion' && this.state.connectingPointScreen === null) {
      this.setState({
        page: 'Mes trajets',
      });
    }
    if (this.state.page === 'Conclusione' && this.state.connectingPointScreen === null) {
      this.setState({
        page: 'La mia panoramica dei viaggi',
      });
    }
    if (this.state.page === 'Completion' && this.state.connectingPointScreen === null) {
      this.setState({ page: 'Travel diary' });
    }

    //BACK FROM COMPETITION PAGE
    if (this.state.page === 'Wettbewerb') {
      this.setState({ page: 'Meine Reiseübersicht' });
    }
    if (this.state.page === 'Concurrence') {
      this.setState({ page: 'Mes trajets' });
    }
    if (this.state.page === 'Concorrenza') {
      this.setState({ page: 'La mia panoramica dei viaggi' });
    }
    if (this.state.page === 'Competition') {
      this.setState({ page: 'Travel diary' });
    }

    //BACK TO HOME PAGE - GENERAL
    if (this.state.page === 'Meine Reiseübersicht' && this.state.connectingPointScreen === null) {
      this.props.history.push('/de');
    }
    if (this.state.page === 'Mes trajets' && this.state.connectingPointScreen === null) {
      this.props.history.push('/fr');
    }
    if (
      this.state.page === 'La mia panoramica dei viaggi' &&
      this.state.connectingPointScreen === null
    ) {
      this.props.history.push('/it');
    }
    if (this.state.page === 'Travel diary' && this.state.connectingPointScreen === null) {
      this.props.history.push('/en');
    }
    //BACK FROM ADDING A TRIP FROM PLUS BUTTON
    if (this.state.from === 'plusButton') {
      if (this.state.page === 'Startort eingeben') {
        this.setState({
          page: 'Meine Reiseübersicht',
          date: null,
          from: null,
        });
      } else if (this.state.page === "Indiquer l'arrêt de départ") {
        this.setState({
          page: 'Mes trajets',
          date: null,
          from: null,
        });
      } else if (this.state.page === 'Immettere luogo di partenza') {
        this.setState({
          page: 'La mia panoramica dei viaggi',
          date: null,
          from: null,
        });
      } else if (this.state.page === 'Departure point') {
        this.setState({
          page: 'Travel diary',
          date: null,
          from: null,
        });
      }
    }

    //BACK FROM EDITING A TRIP
    if (this.state.page === 'Reise bearbeiten') {
      this.setState({
        page: 'Meine Reiseübersicht',
        ExtraStops: [],
        date: null,
        editTripId: null,
        startPointName: null,
        startPointCode: null,
        startTravel: null,
        endTravel: null,
        endPointName: null,
        endPointCode: null,
      });
    } else if (this.state.page === 'Modifica viaggio') {
      this.setState({
        page: 'La mia panoramica dei viaggi',
        ExtraStops: [],
        date: null,
        editTripId: null,
        startPointName: null,
        startPointCode: null,
        startTravel: null,
        endTravel: null,
        endPointName: null,
        endPointCode: null,
      });
    } else if (this.state.page === 'Modifier le voyage') {
      this.setState({
        page: 'Mes trajets',
        ExtraStops: [],
        date: null,
        editTripId: null,
        startPointName: null,
        startPointCode: null,
        startTravel: null,
        endTravel: null,
        endPointName: null,
        endPointCode: null,
      });
    } else if (this.state.page === 'Edit trip') {
      this.setState({
        page: 'Travel diary',
        ExtraStops: [],
        date: null,
        editTripId: null,
        startPointName: null,
        startPointCode: null,
        startTravel: null,
        endTravel: null,
        endPointName: null,
        endPointCode: null,
      });
    }
    //BACK FROM ARRIVAL POINT SELECTION
    if (this.state.connectingPointScreen === null) {
      if (this.state.page === 'Zielort eingeben') {
        this.setState({
          page: 'Startort eingeben',
        });
      } else if (this.state.page === "Indiquer l'arrêt de destination") {
        this.setState({
          page: "Indiquer l'arrêt de départ",
        });
      } else if (this.state.page === 'Immettere destinazione') {
        this.setState({
          page: 'Immettere luogo di partenza',
        });
      } else if (this.state.page === 'Destination') {
        this.setState({
          page: 'Departure point',
        });
      }
      //BACK FROM ADDING MORE IN-BETWEEN POINTS
      if (this.state.page === 'Umsteigeorte hinzufügen') {
        this.setState({
          page: 'Zielort eingeben',
        });
      } else if (this.state.page === 'Ajouter des arrêts de correspondance') {
        this.setState({
          page: "Indiquer l'arrêt de destination",
        });
      } else if (this.state.page === 'Aggiungere punti di cambio') {
        this.setState({
          page: 'Immettere destinazione',
        });
      } else if (this.state.page === 'Places where you changed connections') {
        this.setState({
          page: 'Destination',
        });
      }
    }
    //BACK FROM ADDING MORE IN-BETWEEN STATION SELECTION
    if (this.state.connectingPointScreen === 'newPoint') {
      if (
        this.state.page === 'Umsteigeorte hinzufügen' ||
        this.state.page === 'Ajouter des arrêts de correspondance' ||
        this.state.page === 'Aggiungere punti di cambio' ||
        this.state.page === 'Places where you changed connections'
      ) {
        this.setState({
          connectingPointScreen: null,
          connectingPointIndex: null,
        });
      }
    }
    //BACK FROM CHOSING TRANSPORT TYPES FOR STATIONS
    // if (this.state.connectingPointScreen === "transportType") {
    //   if (
    //     this.state.page === "Umsteigeorte hinzufügen" ||
    //     this.state.page === "Ajouter des arrêts de correspondance" ||
    //     this.state.page === "Aggiungere punti di cambio"
    //   ) {
    //     this.setState({
    //       connectingPointScreen: null,
    //     });
    //   }
    // }

    //TRANSPORT TYPE BACK BUTTON
    if (this.state.page === 'Transportmittel wählen') {
      this.setState({
        page: 'Umsteigeorte hinzufügen',
        connectingPointScreen: null,
        startTravel: null,
      });
    } else if (this.state.page === 'Choisissez un moyen de transport') {
      this.setState({
        page: 'Ajouter des arrêts de correspondance',
        connectingPointScreen: null,
        startTravel: null,
      });
    } else if (this.state.page === 'Scegli il mezzo di trasporto') {
      this.setState({
        page: 'Aggiungere punti di cambio',
        connectingPointScreen: null,
        startTravel: null,
      });
    } else if (this.state.page === 'Types of transport') {
      this.setState({
        page: 'Places where you changed connections',
        connectingPointScreen: null,
        startTravel: null,
      });
    }

    if (this.state.page === 'Anderer Tag / Rückreise') {
      this.setState({
        page: 'Transportmittel wählen',
        connectingPointScreen: 'transportType',
      });
    } else if (this.state.page === 'Autre jour / Retour') {
      this.setState({
        page: 'Choisissez un moyen de transport',
        connectingPointScreen: 'transportType',
      });
    } else if (this.state.page === 'Altro giorno / Ritorno') {
      this.setState({
        page: 'Scegli il mezzo di trasporto',
        connectingPointScreen: 'transportType',
      });
    } else if (this.state.page === 'Other day / Return trip') {
      this.setState({
        page: 'Types of transport',
        connectingPointScreen: 'transportType',
      });
    }
  };

  isUserAuthenticated = () => {
    var auth = localStorage.AccessToken;
    if (auth === undefined) {
      localStorage.clear();
      this.props.history.push('/de/');
    }
  };

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', window.localStorage.AccessToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          userProfile: result.Preload,
          updatedALL: true,
        });
      })
      .catch((error) => console.log('error', error));
  };

  //Saving trips to the database
  postMultipleTrips = (tripsArray) => {
    //Check dates that are true
    let trueDates = {
      to: [],
      return: [],
    };
    for (let i = 0; i < tripsArray.length; i++) {
      if (tripsArray[i].to) {
        trueDates.to.push(tripsArray[i]);
      }
      if (tripsArray[i].return) {
        trueDates.return.push(tripsArray[i]);
      }
    }
    this.postRequest(trueDates).then(() => {
      this.handleHomeScreen(
        window.location.pathname.includes('/fr')
          ? 'Mes trajets'
          : window.location.pathname.includes('/it')
          ? 'La mia panoramica dei viaggi'
          : window.location.pathname.includes('/en')
          ? 'Travel diary'
          : 'Meine Reiseübersicht'
      );
    });
  };

  //POST REQUEST FUNCTION
  postRequest = async (dates) => {
    var proxyURL = process.env.REACT_APP_PROXY_URL;
    var targetURL = `${process.env.REACT_APP_BASE_URL}/api/Journeys/new`;
    var myHeaders = new Headers();
    myHeaders.append('Authorization', window.localStorage.AccessToken);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(
      'Cookie',
      'lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81'
    );
    this.setState({ loading: true });
    var raw;
    await Promise.all(
      dates.to?.map((date) => {
        if (this.state.ExtraStops.length < 1) {
          raw = JSON.stringify({
            JourneyId: '0',
            PeriodEnd: this.state.userProfile.PeriodEnd,
            PeriodStart: this.state.userProfile.PeriodStart,
            JourneyDateString: moment(date.rawDate).format().toString().slice(0, 19),
            TypeNum: '1',
            DepartureStationId: this.state.startPointCode,
            DepartureStationName: this.state.startPointName,
            MeansOfTravel: this.state.startTravel,
            ArrivalStationId: this.state.endPointCode,
            ArrivalStationName: this.state.endPointName,
          });
        } else {
          raw = JSON.stringify({
            JourneyId: '0',
            PeriodEnd: this.state.userProfile.PeriodEnd,
            PeriodStart: this.state.userProfile.PeriodStart,
            JourneyDateString: moment(date.rawDate).format().toString().slice(0, 19),
            TypeNum: '1',
            DepartureStationId: this.state.startPointCode,
            DepartureStationName: this.state.startPointName,
            MeansOfTravel: this.state.startTravel,
            ArrivalStationId: this.state.endPointCode,
            ArrivalStationName: this.state.endPointName,
            ExtraStops: this.state.ExtraStops,
          });
        }
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        return fetch(proxyURL + targetURL, requestOptions)
          .then((response) => {
            return response.json();
          })
          .catch((err) => err);
      })
    );
    //SAVING FOR TRIPS THAT RETURN
    return await Promise.all(
      dates.return?.map((date) => {
        if (this.state.ExtraStops.length < 1) {
          raw = JSON.stringify({
            JourneyId: '0',
            PeriodEnd: this.state.userProfile.PeriodEnd,
            PeriodStart: this.state.userProfile.PeriodStart,
            JourneyDateString: moment(date.rawDate).format().toString().slice(0, 19),
            TypeNum: '1',
            DepartureStationId: this.state.endPointCode,
            DepartureStationName: this.state.endPointName,
            MeansOfTravel: this.state.startTravel,
            ArrivalStationId: this.state.startPointCode,
            ArrivalStationName: this.state.startPointName,
          });
        } else {
          let reversedExtra = [];
          reversedExtra = [...this.state.ExtraStops];
          reversedExtra.reverse();
          let sortStops = [];
          reversedExtra.forEach((plus, index) => {
            if (index < reversedExtra.length - 1) {
              sortStops.push({
                Index: plus.index,
                MeansOfTravel: reversedExtra[index + 1].MeansOfTravel,
                MeansOfTravelIcon: reversedExtra[index + 1].MeansOfTravel,
                MeansOfTravelLabel: reversedExtra[index + 1].MeansOfTravel,
                RenderHelp: plus.RenderHelp,
                ViaId: plus.ViaId,
                ViaStationId: plus.ViaStationId,
                ViaStationName: plus.viaStationName,
              });
            } else {
              sortStops.push({
                Index: plus.index,
                MeansOfTravel: this.state.startTravel,
                MeansOfTravelIcon: this.state.startTravel,
                MeansOfTravelLabel: this.state.startTravel,
                RenderHelp: plus.RenderHelp,
                ViaId: plus.ViaId,
                ViaStationId: plus.ViaStationId,
                ViaStationName: plus.viaStationName,
              });
            }
          });

          raw = JSON.stringify({
            JourneyId: '0',
            PeriodEnd: this.state.userProfile.PeriodEnd,
            PeriodStart: this.state.userProfile.PeriodStart,
            JourneyDateString: moment(date.rawDate).format().toString().slice(0, 19),
            TypeNum: '1',
            DepartureStationId: this.state.endPointCode,
            DepartureStationName: this.state.endPointName,
            MeansOfTravel: reversedExtra[0].MeansOfTravel,
            ArrivalStationId: this.state.startPointCode,
            ArrivalStationName: this.state.startPointName,
            ExtraStops: sortStops,
          });
        }
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        return fetch(proxyURL + targetURL, requestOptions)
          .then((response) => {
            return response.json();
          })
          .catch((err) => err);
      })
    );
  };

  handleNewJourney = (e) => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Journeys/new`;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', window.localStorage.AccessToken);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(
      'Cookie',
      'lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81'
    );

    this.setState({ loading: true });
    if (this.state.ExtraStops.length < 1) {
      var raw = JSON.stringify({
        JourneyId: '0',
        PeriodEnd: this.state.userProfile.PeriodEnd,
        PeriodStart: this.state.userProfile.PeriodStart,
        JourneyDateString: moment(this.state.date).format().toString().slice(0, 19),
        TypeNum: '1',
        DepartureStationId: this.state.startPointCode,
        DepartureStationName: this.state.startPointName,
        MeansOfTravel: this.state.startTravel,
        ArrivalStationId: this.state.endPointCode,
        ArrivalStationName: this.state.endPointName,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      fetch(proxyUrl + targetUrl, requestOptions)
        .then((response) => response.text())
        .then((result) =>
          this.handleHomeScreen(
            window.location.pathname.includes('/fr')
              ? 'Mes trajets'
              : window.location.pathname.includes('/it')
              ? 'La mia panoramica dei viaggi'
              : window.location.pathname.includes('/en')
              ? 'Travel diary'
              : 'Meine Reiseübersicht'
          )
        )
        // setTimeout(() => {
        //   window.location.reload();
        // }, 5000)
        // () => {
        //   window.location.reload();
        // }
        .catch((error) => console.log('error', error));
    } else {
      // eslint-disable-next-line

      if (e === undefined) {
        // eslint-disable-next-line
        var raw = JSON.stringify({
          JourneyId: '0',
          PeriodEnd: this.state.userProfile.PeriodEnd,
          PeriodStart: this.state.userProfile.PeriodStart,
          JourneyDateString: moment(this.state.date).format().toString().slice(0, 19),
          TypeNum: '1',
          DepartureStationId: this.state.startPointCode,
          DepartureStationName: this.state.startPointName,
          MeansOfTravel: this.state.startTravel,
          ArrivalStationId: this.state.endPointCode,
          ArrivalStationName: this.state.endPointName,
          ExtraStops: this.state.ExtraStops,
        });
      } else {
        // eslint-disable-next-line
        var raw = JSON.stringify({
          JourneyId: '0',
          PeriodEnd: this.state.userProfile.PeriodEnd,
          PeriodStart: this.state.userProfile.PeriodStart,
          JourneyDateString: moment(this.state.date).format().toString().slice(0, 19),
          TypeNum: '1',
          DepartureStationId: this.state.startPointCode,
          DepartureStationName: this.state.startPointName,
          MeansOfTravel: this.state.startTravel,
          ArrivalStationId: this.state.endPointCode,
          ArrivalStationName: this.state.endPointName,
          ExtraStops: this.state.ExtraStops,
        });
      }

      // eslint-disable-next-line
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      fetch(proxyUrl + targetUrl, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.handleHomeScreen(
            window.location.pathname.includes('/fr')
              ? 'Mes trajets'
              : window.location.pathname.includes('/it')
              ? 'La mia panoramica dei viaggi'
              : window.location.pathname.includes('/en')
              ? 'Travel diary'
              : 'Meine Reiseübersicht'
          );
        })
        // setTimeout(() => {
        //   window.location.reload();
        //
        // }, 5000)
        // () => {
        //   window.location.reload();
        // }
        .catch((error) => console.log('error', error));
    }
  };

  handleDateChange = (e, text = '') => {
    let newDate = new Date(e);
    window.location.pathname.includes('/fr')
      ? this.setState({
          date: newDate,
          page: "Indiquer l'arrêt de départ",
          from: text,
        })
      : window.location.pathname.includes('/it')
      ? this.setState({
          date: newDate,
          page: 'Immettere luogo di partenza',
          from: text,
        })
      : window.location.pathname.includes('/en')
      ? this.setState({
          date: newDate,
          page: 'Departure point',
          from: text,
        })
      : this.setState({
          date: newDate,
          page: 'Startort eingeben',
          from: text,
        });
  };

  handleDateUpdate = (e) => {
    let newDate = new Date(e);
    this.setState({
      date: newDate,
    });
  };

  handleStartPointUpdate = (e, z) => {
    // console.log("Starting point --------", e);
    // console.log("Ending point --------", z);
    this.setState({
      startPointName: e,
      startPointCode: z,
    });
  };

  handleViaStationUpdate = (index, id, code, name, mt) => {
    const station = {
      index: 0,
      MeansOfTravel: mt,
      MeansOfTravelIcon: mt,
      MeansOfTravelLabel: mt,
      RenderHelp: false,
      ViaId: id,
      ViaStationId: code,
      ViaStationName: name,
    };
    const editPoint = this.state.ExtraStops.slice();
    editPoint.splice(index + 1, 0, station);
    setTimeout(() => {
      editPoint.splice(index, 1);
      this.setState({
        ExtraStops: [...editPoint],
      });
    }, 200);
  };

  handleViaStationTransportTypeUpdate = (
    indexlocal,
    id,
    oldViaStationId,
    oldViaStationName,
    mt
  ) => {
    let i = {
      index: 0,
      MeansOfTravel: mt,
      MeansOfTravelIcon: mt,
      MeansOfTravelLabel: mt,
      RenderHelp: false,
      ViaId: id,
      ViaStationId: oldViaStationId,
      ViaStationName: oldViaStationName,
    };
    this.setState({
      ExtraStops: this.state.ExtraStops.map((el, index) =>
        indexlocal === index ? Object.assign(i) : el
      ),
    });
  };

  handleViaStationTravelType = (stationId, stationName, travelType) => {
    let i = {
      index: '0',
      MeansOfTravel: travelType,
      MeansOfTravelIcon: travelType,
      MeansOfTravelLabel: travelType,
      RenderHelp: false,
      ViaId: '0',
      ViaStationId: stationId,
      viaStationName: stationName,
    };
    return i;
    // this.setState(
    //   {
    //     toSubmitStops: this.state.toSubmitStops.push(i),
    //   },
    //   () => {
    //     console.log("state should be: ", this.state.toSubmitStops);
    //   }
    // );
  };

  viaStationSubmit = (arr) => {
    this.setState(
      {
        ExtraStops: [...arr],
      },
      () => {
        // this.handleNewJourney("splist");
        this.goToFinalStep();
      }
    );
  };

  handleEndPointUpdate = (e, z) => {
    this.setState({
      endPointName: e,
      endPointCode: z,
    });
  };

  handleStartPoint = (e, z = -1) => {
    window.location.pathname.includes('/fr')
      ? this.setState({
          startPointName: e,
          startPointCode: z,
          page: "Indiquer l'arrêt de destination",
        })
      : window.location.pathname.includes('/it')
      ? this.setState({
          startPointName: e,
          startPointCode: z,
          page: 'Immettere destinazione',
        })
      : window.location.pathname.includes('/en')
      ? this.setState({
          startPointName: e,
          startPointCode: z,
          page: 'Destination',
        })
      : this.setState({
          startPointName: e,
          startPointCode: z,
          page: 'Zielort eingeben',
        });
  };

  handleEditSubmit = (deleteId) => {
    if (this.state.ExtraStops > 0) {
      this.handleDeleteTrip(deleteId);
      setTimeout(() => {
        this.handleNewJourney();
      }, 2000);
    } else {
      this.putJourney();
    }
  };

  handleConnectingPoint = (e, z) => {
    const station = {
      ViaId: '0',
      Index: '0',
      RenderHelp: 'false',
      ViaStationId: z,
      ViaStationName: e,
    };
    const newStation = this.state.initExtraStops.slice();
    newStation.splice(this.state.connectingPointIndex, 0, station);
    this.setState({
      initExtraStops: newStation,
      connectingPointScreen: null,
    });
  };

  handleExtraStationsState = (id, name, travel) => {
    // this.setState({ loading: true });
    const station = {
      ViaId: '0',
      Index: '0',
      RenderHelp: 'false',
      ViaStationId: id,
      ViaStationName: name,
      MeansOfTravel: travel,
    };
    const newStation = this.state.initExtraStops;
    newStation.push(station);
    this.setState({
      ExtraStops: newStation,
    });
  };

  removeInitConnectingPoint = (e) => {
    const stations = this.state.initExtraStops.filter((station) => station.ViaStationName !== e);
    this.setState({
      initExtraStops: stations,
    });
  };

  stationsPopper = () => {
    const stations = this.state.ExtraStops;

    stations.pop();

    this.setState({
      ExtraStops: stations,
    });
  };

  removeConnectingPoint = (stationId) => {
    const stations = this.state.ExtraStops.filter((station, index) => {
      if (stationId !== index) {
        return true;
      }
      return false;
    });

    this.setState({
      ExtraStops: [...stations],
    });
  };

  handleEndPoint = (e, z) => {
    window.location.pathname.includes('/fr')
      ? this.setState({
          endPointName: e,
          endPointCode: z,
          page: 'Ajouter des arrêts de correspondance',
        })
      : window.location.pathname.includes('/it')
      ? this.setState({
          endPointName: e,
          endPointCode: z,
          page: 'Aggiungere punti di cambio',
        })
      : window.location.pathname.includes('/en')
      ? this.setState({
          endPointName: e,
          endPointCode: z,
          page: 'Places where you changed connections',
        })
      : this.setState({
          endPointName: e,
          endPointCode: z,
          page: 'Umsteigeorte hinzufügen',
        });
  };

  handleConnectingPointScreen = (e, n) => {
    window.location.pathname.includes('/fr')
      ? this.setState({
          connectingPointScreen: e,
          connectingPointIndex: n,
          // page: "Choisissez un moyen de transport",
        })
      : window.location.pathname.includes('/it')
      ? this.setState({
          connectingPointScreen: e,
          connectingPointIndex: n,
          // page: "Scegli il mezzo di trasporto",
        })
      : window.location.pathname.includes('/en')
      ? this.setState({
          connectingPointScreen: e,
          connectingPointIndex: n,
          // page: "Transportmittel wählen",
        })
      : this.setState({
          connectingPointScreen: e,
          connectingPointIndex: n,
          // page: "Transportmittel wählen",
        });
  };
  handleConnectingPointScreenButton = (e, n = null) => {
    window.location.pathname.includes('/fr')
      ? this.setState({
          connectingPointScreen: e,
          connectingPointIndex: n,
          page: 'Choisissez un moyen de transport',
        })
      : window.location.pathname.includes('/it')
      ? this.setState({
          connectingPointScreen: e,
          connectingPointIndex: n,
          page: 'Scegli il mezzo di trasporto',
        })
      : window.location.pathname.includes('/en')
      ? this.setState({
          connectingPointScreen: e,
          connectingPointIndex: n,
          page: 'Types of transport',
        })
      : this.setState({
          connectingPointScreen: e,
          connectingPointIndex: n,
          page: 'Transportmittel wählen',
        });
  };
  //Clear Main state function
  clearState = () => {
    this.setState({
      startPointName: null,
      startPointCode: null,
      endPointName: null,
      endPointCode: null,
      ExtraStops: [],
      initExtraStops: [],
      from: null,
      connectingPointIndex: null,
      connectingPointScreen: null,
      date: null,
      startTravel: null,
    });
  };
  handleHomeScreen = (e) => {
    this.clearState();
    this.loadingOff();
    setTimeout(() => {
      this.setState({
        page: e,
        connectingPointScreen: null,
      });
    }, 500);
  };

  handleStartTravel = (e) => {
    this.setState({
      startTravel: e,
    });
  };

  handleEndTravel = (e) => {
    this.setState({
      endTravel: e,
    });
  };

  handleDuplicateTrip = (ID, d, spn, spc, epn, epc, st, es, page) => {
    window.location.pathname.includes('/fr')
      ? this.setState({
          editTripId: ID,
          date: d,
          startPointName: spn,
          startPointCode: spc,
          endPointName: epn,
          endPointCode: epc,
          startTravel: st,
          ExtraStops: es,
          page: page || 'Copier le trajet',
        })
      : window.location.pathname.includes('/it')
      ? this.setState({
          editTripId: ID,
          date: d,
          startPointName: spn,
          startPointCode: spc,
          endPointName: epn,
          endPointCode: epc,
          startTravel: st,
          ExtraStops: es,
          page: page || 'Copiare viaggio',
        })
      : this.setState({
          editTripId: ID,
          date: d,
          startPointName: spn,
          startPointCode: spc,
          endPointName: epn,
          endPointCode: epc,
          startTravel: st,
          ExtraStops: es,
          page: page || 'Reise kopieren',
        });
  };

  handleTripBack = (e, page) => {
    let newDate = new Date(e);
    this.setState({
      date: newDate,
      page: page,
    });

    var lastTrip = this.state.tripsData[this.state.tripsData.length - 1];
    this.handleDuplicateTrip(
      lastTrip.JourneyId,
      newDate,
      lastTrip.ArrivalStationName,
      lastTrip.ArrivalStationId,
      lastTrip.DepartureStationName,
      lastTrip.DepartureStationId,
      lastTrip.MeansOfTravel,
      lastTrip.ExtraStops.reverse(),
      page
    );
  };

  handleTripBackWithExtra = (e, page) => {
    let newDate = new Date(e);
    this.setState({
      date: newDate,
      page: page,
    });

    var lastTrip = this.state.tripsData[this.state.tripsData.length - 1];

    var reverseStops = lastTrip.ExtraStops.reverse();

    // console.log('reverseStops: ', reverseStops);

    var sortedStops = [];

    reverseStops.map((s, index) =>
      index < reverseStops.length - 1
        ? sortedStops.push({
            Index: s.Index,
            MeansOfTravel: reverseStops[index + 1].MeansOfTravel,
            MeansOfTravelIcon: reverseStops[index + 1].MeansOfTravel,
            MeansOfTravelLabel: reverseStops[index + 1].MeansOfTravel,
            RenderHelp: s.RenderHelp,
            ViaId: s.ViaId,
            ViaStationId: s.ViaStationId,
            ViaStationName: s.ViaStationName,
          })
        : sortedStops.push({
            Index: s.Index,
            MeansOfTravel: lastTrip.MeansOfTravel,
            MeansOfTravelIcon: lastTrip.MeansOfTravel,
            MeansOfTravelLabel: lastTrip.MeansOfTravel,
            RenderHelp: s.RenderHelp,
            ViaId: s.ViaId,
            ViaStationId: s.ViaStationId,
            ViaStationName: s.ViaStationName,
          })
    );

    // console.log("sortedStops: ", sortedStops);

    this.handleDuplicateTrip(
      lastTrip.JourneyId,
      newDate,
      lastTrip.ArrivalStationName,
      lastTrip.ArrivalStationId,
      lastTrip.DepartureStationName,
      lastTrip.DepartureStationId,
      reverseStops[0].MeansOfTravel,
      sortedStops,
      page
    );
  };

  handleDeleteTrip = (tripId, e) => {
    if (e) e.stopPropagation();
    this.setState({ loading: true });
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Journeys/delete`;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', window.localStorage.AccessToken);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Cookie', 'lang=de');

    var raw = JSON.stringify({
      DeleteJourneyId: tripId,
    });

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .then(
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      )
      .catch((error) => console.log('error', error));
  };

  handleEditTrip = (ID, d, spn, spc, epn, epc, st, es) => {
    window.location.pathname.includes('/fr')
      ? this.setState({
          editTripId: ID,
          date: d,
          startPointName: spn,
          startPointCode: spc,
          endPointName: epn,
          endPointCode: epc,
          startTravel: st,
          ExtraStops: es,
          page: 'Modifier le voyage',
        })
      : window.location.pathname.includes('/it')
      ? this.setState({
          editTripId: ID,
          date: d,
          startPointName: spn,
          startPointCode: spc,
          endPointName: epn,
          endPointCode: epc,
          startTravel: st,
          ExtraStops: es,
          page: 'Modifica viaggio',
        })
      : window.location.pathname.includes('/en')
      ? this.setState({
          editTripId: ID,
          date: d,
          startPointName: spn,
          startPointCode: spc,
          endPointName: epn,
          endPointCode: epc,
          startTravel: st,
          ExtraStops: es,
          page: 'Edit trip',
        })
      : this.setState({
          editTripId: ID,
          date: d,
          startPointName: spn,
          startPointCode: spc,
          endPointName: epn,
          endPointCode: epc,
          startTravel: st,
          ExtraStops: es,
          page: 'Reise bearbeiten',
        });
  };

  handleNewViaStationUpdate = (index, id) => {
    const station = {
      index: 0,
      MeansOfTravel: null,
      MeansOfTravelIcon: null,
      MeansOfTravelLabel: null,
      RenderHelp: false,
      ViaId: id,
      ViaStationId: index,
      ViaStationName: null,
      newStop: true,
    };
    let newPoint = this.state.ExtraStops.slice();
    newPoint.splice(index, 0, station);
    this.setState({
      ExtraStops: [...newPoint],
    });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  putJourney = () => {
    this.setState({ loading: true });
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Journeys/edit`;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', window.localStorage.AccessToken);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(
      'Cookie',
      'lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81'
    );

    var raw = JSON.stringify({
      JourneyId: this.state.editTripId,
      PeriodEnd: this.state.userProfile.PeriodEnd,
      PeriodStart: this.state.userProfile.PeriodStart,
      JourneyDateString: moment(this.state.date).format().toString().slice(0, 19),
      TypeNum: '1',
      DepartureStationId: this.state.startPointCode,
      DepartureStationName: this.state.startPointName,
      MeansOfTravel: this.state.startTravel,
      ArrivalStationId: this.state.endPointCode,
      ArrivalStationName: this.state.endPointName,
      ExtraStops: this.state.ExtraStops,
    });
    // console.log(this.state.ExtraStops);
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .then(
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      )
      .catch((error) => console.log('error', error));
  };

  finishHandler = (param) => {
    this.setState({
      page: param,
    });
  };

  loadingOn = () => {
    this.setState({ loading: true });
  };

  loadingOff = () => {
    this.setState({ loading: false });
  };

  goToFinalStep = () => {
    this.setState({
      page: window.location.pathname.includes('/fr')
        ? 'Autre jour / Retour'
        : window.location.pathname.includes('/it')
        ? 'Altro giorno / Ritorno'
        : window.location.pathname.includes('/en')
        ? 'Other day / Return trip'
        : 'Anderer Tag / Rückreise',
    });
  };
  //Go to main from anywhere
  toMainScreen = () => {
    this.setState({
      page: window.location.pathname.includes('/fr')
        ? 'Mes trajets'
        : window.location.pathname.includes('/it')
        ? 'La mia panoramica dei viaggi'
        : window.location.pathname.includes('/en')
        ? 'Travel diary'
        : 'Meine Reiseübersicht',
    });
  };
  render() {
    return (
      <React.Fragment>
        <AppBar
          state={this.state}
          backButtonHandler={this.backButtonHandler}
          toMainScreen={this.toMainScreen}
        />
        <PageTitle title={this.state.page} />
        {this.state.page === 'Meine Reiseübersicht' ? (
          <MainHomeScreen
            state={this.state}
            handleDuplicateTrip={this.handleDuplicateTrip}
            handleDeleteTrip={this.handleDeleteTrip}
            handleEditTrip={this.handleEditTrip}
            finishHandler={this.finishHandler}
            handleTripBack={this.handleTripBack}
            handleTripBackWithExtra={this.handleTripBackWithExtra}
            backButtonChecker={this.backButtonChecker}
            handleDateChange={this.handleDateChange}
            backButtonHandler={this.backButtonHandler}
          />
        ) : this.state.page === 'Mes trajets' ? (
          <MainHomeScreen
            state={this.state}
            handleDuplicateTrip={this.handleDuplicateTrip}
            handleDeleteTrip={this.handleDeleteTrip}
            handleEditTrip={this.handleEditTrip}
            finishHandler={this.finishHandler}
            handleTripBack={this.handleTripBack}
            handleTripBackWithExtra={this.handleTripBackWithExtra}
            backButtonChecker={this.backButtonChecker}
            backButtonHandler={this.backButtonHandler}
            handleDateChange={this.handleDateChange}
          />
        ) : this.state.page === 'La mia panoramica dei viaggi' ? (
          <MainHomeScreen
            state={this.state}
            handleDuplicateTrip={this.handleDuplicateTrip}
            handleDeleteTrip={this.handleDeleteTrip}
            handleEditTrip={this.handleEditTrip}
            finishHandler={this.finishHandler}
            handleTripBack={this.handleTripBack}
            handleTripBackWithExtra={this.handleTripBackWithExtra}
            backButtonChecker={this.backButtonChecker}
            backButtonHandler={this.backButtonHandler}
            handleDateChange={this.handleDateChange}
          />
        ) : this.state.page === 'Travel diary' ? (
          <MainHomeScreen
            state={this.state}
            handleDuplicateTrip={this.handleDuplicateTrip}
            handleDeleteTrip={this.handleDeleteTrip}
            handleEditTrip={this.handleEditTrip}
            finishHandler={this.finishHandler}
            handleTripBack={this.handleTripBack}
            handleTripBackWithExtra={this.handleTripBackWithExtra}
            backButtonChecker={this.backButtonChecker}
            backButtonHandler={this.backButtonHandler}
            handleDateChange={this.handleDateChange}
          />
        ) : this.state.page === 'Startort eingeben' ||
          this.state.page === "Indiquer l'arrêt de départ" ||
          this.state.page === 'Immettere luogo di partenza' ||
          this.state.page === 'Departure point' ? (
          <StartortScreen
            state={this.state}
            handleStartPoint={this.handleStartPoint}
            backButtonChecker={this.backButtonChecker}
            backButtonHandler={this.backButtonHandler}
          />
        ) : this.state.page === 'Zielort eingeben' ||
          this.state.page === "Indiquer l'arrêt de destination" ||
          this.state.page === 'Immettere destinazione' ||
          this.state.page === 'Destination' ? (
          <ZielortScreen
            state={this.state}
            handleEndPoint={this.handleEndPoint}
            backButtonChecker={this.backButtonChecker}
            backButtonHandler={this.backButtonHandler}
          />
        ) : this.state.page === 'Choisissez un moyen de transport' ||
          this.state.page === 'Scegli il mezzo di trasporto' ||
          this.state.page === 'Transportmittel wählen' ||
          this.state.page === 'Types of transport' ? (
          <UmsteigeorteScreen
            state={this.state}
            handleConnectingPointScreen={this.handleConnectingPointScreen}
            handleConnectingPoint={this.handleConnectingPoint}
            handleHomeScreen={this.handleHomeScreen}
            handleStartTravel={this.handleStartTravel}
            handleEndTravel={this.handleEndTravel}
            handleNewJourney={this.handleNewJourney}
            removeInitConnectingPoint={this.removeInitConnectingPoint}
            handleExtraStationsState={this.handleExtraStationsState}
            stationsPopper={this.stationsPopper}
            handleViaStationTravelType={this.handleViaStationTravelType}
            viaStationSubmit={this.viaStationSubmit}
            loadingOn={this.loadingOn}
            loadingOff={this.loadingOff}
            backButtonChecker={this.backButtonChecker}
            backButtonHandler={this.backButtonHandler}
          />
        ) : this.state.page === 'Umsteigeorte hinzufügen' ||
          this.state.page === 'Ajouter des arrêts de correspondance' ||
          this.state.page === 'Aggiungere punti di cambio' ||
          this.state.page === 'Places where you changed connections' ? (
          <UmsteigeorteScreen
            state={this.state}
            handleConnectingPointScreenButton={this.handleConnectingPointScreenButton}
            handleConnectingPointScreen={this.handleConnectingPointScreen}
            handleConnectingPoint={this.handleConnectingPoint}
            handleHomeScreen={this.handleHomeScreen}
            handleStartTravel={this.handleStartTravel}
            handleEndTravel={this.handleEndTravel}
            handleNewJourney={this.handleNewJourney}
            removeInitConnectingPoint={this.removeInitConnectingPoint}
            handleExtraStationsState={this.handleExtraStationsState}
            stationsPopper={this.stationsPopper}
            handleViaStationTravelType={this.handleViaStationTravelType}
            viaStationSubmit={this.viaStationSubmit}
            loadingOn={this.loadingOn}
            loadingOff={this.loadingOff}
            backButtonChecker={this.backButtonChecker}
            backButtonHandler={this.backButtonHandler}
          />
        ) : this.state.page === 'Reise bearbeiten' ||
          this.state.page === 'Modifier le voyage' ||
          this.state.page === 'Modifica viaggio' ||
          this.state.page === 'Edit trip' ? (
          <UpdateJourney
            state={this.state}
            handleDateUpdate={this.handleDateUpdate}
            handleStartTravel={this.handleStartTravel}
            handleStartPointUpdate={this.handleStartPointUpdate}
            handleEndPointUpdate={this.handleEndPointUpdate}
            onChangeHandler={this.onChangeHandler}
            putJourney={this.putJourney}
            removeConnectingPoint={this.removeConnectingPoint}
            handleViaStationUpdate={this.handleViaStationUpdate}
            handleEditSubmit={this.handleEditSubmit}
            handleViaStationTransportTypeUpdate={this.handleViaStationTransportTypeUpdate}
            handleNewViaStationUpdate={this.handleNewViaStationUpdate}
            backButtonChecker={this.backButtonChecker}
            backButtonHandler={this.backButtonHandler}
          />
        ) : this.state.page === 'Abschluss' || this.state.page === 'Wettbewerb' ? (
          <FinishJourney
            state={this.state}
            backButtonChecker={this.backButtonChecker}
            contestPage={this.contestPage}
            backButtonHandler={this.backButtonHandler}
          />
        ) : this.state.page === 'Conclusion' || this.state.page === 'Concurrence' ? (
          <FinishJourney
            state={this.state}
            backButtonChecker={this.backButtonChecker}
            contestPage={this.contestPage}
            backButtonHandler={this.backButtonHandler}
          />
        ) : this.state.page === 'Conclusione' || this.state.page === 'Concorrenza' ? (
          <FinishJourney
            state={this.state}
            backButtonChecker={this.backButtonChecker}
            contestPage={this.contestPage}
            backButtonHandler={this.backButtonHandler}
          />
        ) : this.state.page === 'Completion' || this.state.page === 'Competition' ? (
          <FinishJourney
            state={this.state}
            backButtonChecker={this.backButtonChecker}
            contestPage={this.contestPage}
            backButtonHandler={this.backButtonHandler}
          />
        ) : (this.state.page === 'Altro giorno / Ritorno' ||
            this.state.page === 'Anderer Tag / Rückreise' ||
            this.state.page === 'Autre jour / Retour' ||
            this.state.page === 'Other day / Return trip') &&
          this.state.userProfile.length !== 0 ? (
          <MultipleTrips
            state={this.state}
            backButtonHandler={this.backButtonHandler}
            postMultipleTrips={this.postMultipleTrips}
          />
        ) : null}
        {/* <NavBar state={this.state} /> */}
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
