import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import { Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Switch from "@material-ui/core/Switch";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  emailField: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function RegisterPage() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    emailNotifications: true,
    pushNotifications: false,
    email: "",
    fade: "out",
    loading: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangePush = (event) => {
    var truepush = window.truepush || [];
    truepush.subscribeUserVapid();
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  // console.log("PROPS: ", state);

  const registerHandler = (a, b, c) => {
    setState({ ...state, loading: true });
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/activate`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("Email", a);
    urlencoded.append("EmailRepeat", a);
    urlencoded.append("EnableDailyNotifications", b);
    urlencoded.append("EnablePushNotificationReminder", c);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {})
      .catch((error) => console.log("error", error));
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop className='registerBackdrop' open={state.loading}>
        <CircularProgress color='primary' />
      </Backdrop>
      <Container maxWidth='false' disableGutters='true'>
        {window.location.pathname.includes("/fr") ? (
          <Typography component='div' variant='h6' className='welcomeBanner'>
            Bienvenue! Veuillez compléter vos indications.
          </Typography>
        ) : window.location.pathname.includes("/it") ? (
          <Typography component='div' variant='h6' className='welcomeBanner'>
            Benvenuto/a! La preghiamo di completare i suoi dati.
          </Typography>
        ) : (
          <Typography component='div' variant='h6' className='welcomeBanner'>
            Willkommen! Bitte vervollständigen Sie Ihre Angaben.
          </Typography>
        )}
      </Container>
      <Card
        raised='true'
        className={"errorDialog registerError fade-" + state.fade}
      >
        <CardContent className='errorContent'>
          <Typography align='center'>
            Mit dieser E-Mail Adresse wurde bereits eine Registrierung
            vorgenommen.
          </Typography>
        </CardContent>
      </Card>
      <Container className={classes.emailField}>
        {window.location.pathname.includes("/fr") ? (
          <TextField
            id='email'
            label='Veuillez indiquer votre adresse e-mail.'
            variant='outlined'
            fullWidth='true'
            onChange={onChangeHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <EditOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        ) : window.location.pathname.includes("/it") ? (
          <TextField
            id='email'
            label='Immetta il suo indirizzo e-mail.'
            variant='outlined'
            fullWidth='true'
            onChange={onChangeHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <EditOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField
            id='email'
            label='Bitte geben Sie Ihre E-Mail-Adresse ein.'
            variant='outlined'
            fullWidth='true'
            onChange={onChangeHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <EditOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Container>
      <Container>
        {window.location.pathname.includes("/fr") ? (
          <Typography
            component='div'
            variant='p'
            className='notificationsParagraph'
          >
            Souhaitez-vous recevoir chaque jour un rappel de la saisie des
            voyages?
          </Typography>
        ) : window.location.pathname.includes("/it") ? (
          <Typography
            component='div'
            variant='p'
            className='notificationsParagraph'
          >
            Desidera ricevere un promemoria giornaliero per la registrazione dei
            viaggi?
          </Typography>
        ) : (
          <Typography
            component='div'
            variant='p'
            className='notificationsParagraph'
          >
            Wünschen Sie eine tägliche Erinnerung für die Reiseerfassung per
            E-Mail?
          </Typography>
        )}
      </Container>
      {window.location.pathname.includes("/fr") ? (
        <Container>
          <Typography component='div' className='notificationTogglers'>
            E-Mail
            <IOSSwitch
              checked={state.emailNotifications}
              onChange={handleChange}
              name='emailNotifications'
              inputProps={{ "aria-label": "email notifications" }}
            />
          </Typography>
          <Typography component='div' className='notificationTogglers'>
            Notifications Push
            <IOSSwitch
              checked={state.pushNotifications}
              onChange={handleChangePush}
              name='pushNotifications'
              inputProps={{ "aria-label": "push notifications" }}
            />
          </Typography>
          <Link id='redirectLink' to='/home'>
            redirect
          </Link>
        </Container>
      ) : window.location.pathname.includes("/it") ? (
        <Container>
          <Typography component='div' className='notificationTogglers'>
            E-Mail
            <IOSSwitch
              checked={state.emailNotifications}
              onChange={handleChange}
              name='emailNotifications'
              inputProps={{ "aria-label": "email notifications" }}
            />
          </Typography>
          <Typography component='div' className='notificationTogglers'>
            Notifica Push
            <IOSSwitch
              checked={state.pushNotifications}
              onChange={handleChangePush}
              name='pushNotifications'
              inputProps={{ "aria-label": "push notifications" }}
            />
          </Typography>
          <Link id='redirectLink' to='/home'>
            redirect
          </Link>
        </Container>
      ) : (
        <Container>
          <Typography component='div' className='notificationTogglers'>
            E-Mail
            <IOSSwitch
              checked={state.emailNotifications}
              onChange={handleChange}
              name='emailNotifications'
              inputProps={{ "aria-label": "email notifications" }}
            />
          </Typography>
          <Link id='redirectLink' to='/home'>
            redirect
          </Link>
        </Container>
      )}
      <Container className='registerPolicy'>
        {window.location.pathname.includes("/fr") ? (
          <Typography className='cookieLink'>
            Notre site web utilise des cookies. Pour davantage d'informations
            concernant les cookies, veuillez consulter notre{" "}
            <a
              href='https://www.allianceswisspass.ch/fr/asp/Downloadsindex.php?section=downloads&download=19456'
              className='policyButton'
              disableElevation
              size='large'
              fullWidth
            >
              déclaration de protection des données
            </a>
            .
          </Typography>
        ) : window.location.pathname.includes("/it") ? (
          <Typography className='cookieLink'>
            Il nostro sito web utilizza i cookie. Per ulteriori informazioni sui
            cookie, consultare la nostra{" "}
            <a
              href='https://www.allianceswisspass.ch/it/asp/Downloadsindex.php?section=downloads&download=19456'
              className='policyButton'
              disableElevation
              size='large'
              fullWidth
            >
              politica sulla privacy
            </a>
            .
          </Typography>
        ) : (
          <Typography className='cookieLink'>
            Unsere Website verwendet Cookies. Weitere Informationen zu Cookies
            erhalten Sie in unserer{" "}
            <a
              href='https://www.allianceswisspass.ch/de/asp/Downloadsindex.php?section=downloads&download=19456'
              className='policyButton'
              disableElevation
              size='large'
              fullWidth
            >
              Datenschutzerklärung
            </a>
            .
          </Typography>
        )}
      </Container>
      {window.location.pathname.includes("/fr") ? (
        <div>
          {state.email.includes("@") ? (
            <Link
              onClick={() =>
                registerHandler(
                  state.email,
                  state.emailNotifications,
                  state.pushNotifications
                )
              }
              to='/home'
            >
              <BottomNavigation
                className='registerBottomNav'
                style={{ backgroundColor: "#02B05A" }}
              >
                <BottomNavigationAction
                  label='Inscription'
                  style={{ color: "#ffffff" }}
                />
              </BottomNavigation>
            </Link>
          ) : (
            <BottomNavigation
              className='registerBottomNav'
              style={{ backgroundColor: "#EEEEEE" }}
            >
              <BottomNavigationAction disabled='true' label='Inscription' />
            </BottomNavigation>
          )}
        </div>
      ) : window.location.pathname.includes("/it") ? (
        <div>
          {state.email.includes("@") ? (
            <Link
              onClick={() =>
                registerHandler(
                  state.email,
                  state.emailNotifications,
                  state.pushNotifications
                )
              }
              to='/home'
            >
              <BottomNavigation
                className='registerBottomNav'
                style={{ backgroundColor: "#02B05A" }}
              >
                <BottomNavigationAction
                  label='Registrazione'
                  style={{ color: "#ffffff" }}
                />
              </BottomNavigation>
            </Link>
          ) : (
            <BottomNavigation
              className='registerBottomNav'
              style={{ backgroundColor: "#EEEEEE" }}
            >
              <BottomNavigationAction disabled='true' label='Registrazione' />
            </BottomNavigation>
          )}
        </div>
      ) : (
        <div>
          {state.email.includes("@") ? (
            <Link
              onClick={() =>
                registerHandler(
                  state.email,
                  state.emailNotifications,
                  state.pushNotifications
                )
              }
              to='/home'
            >
              <BottomNavigation
                className='registerBottomNav'
                style={{ backgroundColor: "#02B05A" }}
              >
                <BottomNavigationAction
                  label='Registrieren'
                  style={{ color: "#ffffff" }}
                />
              </BottomNavigation>
            </Link>
          ) : (
            <BottomNavigation
              className='registerBottomNav'
              style={{ backgroundColor: "#EEEEEE" }}
            >
              <BottomNavigationAction disabled='true' label='Registrieren' />
            </BottomNavigation>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
