// You have to pass 2 date objects for the function to generate the interval of dates
export default function (start, end) {
  // console.log("start :", start);
  // console.log("end : ", end);
  for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  // console.log(arr);
  return arr;
}
