import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AppBar from "../../Shared/AppBar";
import NavBar from "../../Shared/NavBar";
import querystring from "querystring";
// import Cry from "../../assets/icons/crying-baby-svgrepo-com.svg";
import "./styles.css";

class Main extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes("/fr")
      ? (this.state = {
          page: "Se déinscrire",
          back: false,
          loading: true,
          unsubscribed: false,
        })
      : window.location.pathname.includes("/it")
      ? (this.state = {
          page: "Annullamento registrazione",
          back: false,
          loading: true,
          unsubscribed: false,
        })
      : (this.state = {
          page: "Abmeldung",
          back: false,
          loading: true,
          unsubscribed: false,
        });
  }

  componentDidMount() {
    this.unsubscribeHandler();
  }

  backButtonHandler = () => {
    this.state.page === "Vielen Dank"
      ? this.props.history.push("/fr")
      : this.state.page === "Vielen Dank"
      ? this.props.history.push("/it")
      : this.props.history.push("/de");
  };

  unsubscribeHandler = () => {
    let params = querystring.parse(this.props.location.search);
    console.log("params:====", params["?TNC"]);
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Email/Unsubscribe?TNC=`;

    if (params["?TNC"] === undefined) {
      // console.log('Params NULL');
      this.setState({ loading: false });
    } else {
      localStorage.clear();

      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(proxyUrl + targetUrl + params["?TNC"], requestOptions)
        .then((response) => {
          response.text();
          console.log("Response code: ", response.status);
          if (response.status === 200) {
            this.setState({ unsubscribed: true });
          }
        })
        .then((result) => {
          console.log("Result after unsubscribe: ", result);
          this.setState({ loading: false });
        })
        .catch((error) => console.log("error", error));
    }
  };

  render() {
    return (
      <React.Fragment>
        <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
        <CssBaseline />
        {this.state.loading ? (
          "Unsubscribing...."
        ) : this.state.unsubscribed ? (
          <Container className='finishSection' maxWidth='false'>
            <div>
              {window.location.pathname.includes("/fr") ? (
                <p>
                  Vous êtes maintenant désinscrit(e) de nos listes. Vous ne
                  recevrez plus d'invitations à cette enquête.
                </p>
              ) : window.location.pathname.includes("/it") ? (
                <p>
                  Ha annullato con successo la sua registrazione. Non le
                  invieremo alcun ulteriore invito per questo sondaggio.{" "}
                </p>
              ) : (
                <p>
                  Sie haben sich erfolgreich abgemeldet. Wir werden Ihnen keine
                  weiteren Einladungen zu dieser Umfrage mehr zustellen.{" "}
                </p>
              )}
            </div>
          </Container>
        ) : (
          <Container className='finishSection' maxWidth='false'>
            <div>
              {window.location.pathname.includes("/fr") ? (
                <p>
                  L'utilisateur avec ce TNC n'a pas été trouvé pour se
                  désinscrire
                </p>
              ) : window.location.pathname.includes("/it") ? (
                <p>
                  L'utente con quel TNC non è stato trovato per annullare
                  l'iscrizione.{" "}
                </p>
              ) : (
                <p>Benutzer mit diesem TNC konnte sich nicht abmelden </p>
              )}
            </div>
          </Container>
        )}
        <NavBar state={this.state} />
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
