import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import BottomNav from "../../../../Shared/BottomNav";
import * as moment from "moment";
import "moment/locale/de";
import "./styles.css";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      tripsData: null,
      totalTrips: 0,
      datesRange: [],
      reason: null,
    };
  }

  render() {
    // console.log('REASON PROPS: ', this.props);

    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth='false' disableGutters='true'>
          <Container>
            {window.location.pathname.includes("/fr") ? (
              <Typography component='div' variant='h6'>
                <br />
                <br />
                Confirmation de non-utilisation de l'AG
                <br />
                <br />
              </Typography>
            ) : window.location.pathname.includes("/it") ? (
              <Typography component='div' variant='h6'>
                <br />
                <br />
                Conferma mancato utilizzo AG
                <br />
                <br />
              </Typography>
            ) : window.location.pathname.includes("/en") ? (
              <Typography component='div' variant='h6'>
                <br />
                <br />
                Confirmation of non-use of the GA travelcard
                <br />
                <br />
              </Typography>
            ) : (
              <Typography component='div' variant='h6'>
                <br />
                <br />
                Bestätigung Nichtnutzung GA
                <br />
                <br />
              </Typography>
            )}
          </Container>
          <Container>
            <FormControl fullWidth component='fieldset'>
              <RadioGroup
                aria-label='gender'
                name='reason'
                onChange={this.props.reasonHandler}
              >
                {window.location.pathname.includes("/fr") ? (
                  <FormControlLabel
                    value='Andere Gründe'
                    control={<Radio />}
                    label={`Je n'ai effectué aucun trajet avec mon abonnement général du ${moment(
                      this.props.state.periodStart
                    ).format("DD.MM.YYYY")} au ${moment(
                      this.props.state.periodEnd
                    ).format(
                      "DD.MM.YYYY"
                    )}  (parce que je l'avais déposé, parce que j'étais en vacances, etc.)`}
                    // label="Je n'ai effectué aucun trajet avec mon abonnement général du #VON# au #BIS# (parce que je l'avais déposé, parce que j'étais en vacances, etc.)"
                  />
                ) : window.location.pathname.includes("/it") ? (
                  <FormControlLabel
                    value='Andere Gründe'
                    control={<Radio />}
                    label={`Dal ${moment(this.props.state.periodStart).format(
                      "DD.MM.YYYY"
                    )} al ${moment(this.props.state.periodEnd).format(
                      "DD.MM.YYYY"
                    )} non ho effettuato alcun viaggio con il mio abbonamento generale.`}
                    // label="Dal #VON# al #BIS# non ho effettuato alcun viaggio con il mio abbonamento generale."
                  />
                ) : window.location.pathname.includes("/en") ? (
                  <FormControlLabel
                    value='Andere Gründe'
                    control={<Radio />}
                    label={`I did not travel with my GA travelcard from ${moment(
                      this.props.state.periodStart
                    ).format("DD.MM.YYYY")} until ${moment(
                      this.props.state.periodEnd
                    ).format("DD.MM.YYYY")}`}
                    // label="Dal #VON# al #BIS# non ho effettuato alcun viaggio con il mio abbonamento generale."
                  />
                ) : (
                  <FormControlLabel
                    value='Andere Gründe'
                    control={<Radio />}
                    label={`Ich habe mit meinem Generalabonnement vom ${moment(
                      this.props.state.periodStart
                    ).format("DD.MM.YYYY")} - ${moment(
                      this.props.state.periodEnd
                    ).format("DD.MM.YYYY")} keine Reisen unternommen.`}
                    // "Ich habe mit meinem Generalabonnement vom #VON# - #BIS# keine Reisen unternommen (beispielsweise aufgrund Hinterlegung, Ferien etc.). "
                  />
                )}
                {/* <FormControlLabel value="GA hinterlegt" control={<Radio />} label="GA hinterlegt" />
                        <FormControlLabel value="Militärdienst / Zivildienst" control={<Radio />} label="Militärdienst / Zivildienst" />
                        <FormControlLabel value="Krankheit/Unfall" control={<Radio />} label="Krankheit/Unfall" />
                        <FormControlLabel value="Andere" control={<Radio />} label="Andere" /> */}
              </RadioGroup>
              {/* <TextField disabled={ this.props.state.reason === 'Andere' ? false : true } fullWidth onChange={this.props.reasonHandler} name="reasonFreeText" id="outlined-basic" label="Grund eingeben" value={this.props.state.reasonFreeText} variant="outlined" /> */}
            </FormControl>
          </Container>
        </Container>
        {/* <BottomNavigation
          onClick={() => this.props.dialogHandler(true)}
          className='homeCompleteButton connectingContinueButton'
          style={{ backgroundColor: "#EEEEEE" }}
        >
          {window.location.pathname.includes("/fr") ? (
            <Typography
              variant='body1'
              className='connectingContinueButtonContent'
            >
              Terminer définitivement l'enquête
            </Typography>
          ) : window.location.pathname.includes("/it") ? (
            <Typography
              variant='body1'
              className='connectingContinueButtonContent'
            >
              Concludere definitivamente il sondaggio
            </Typography>
          ) : (
            <Typography
              variant='body1'
              className='connectingContinueButtonContent'
            >
              Umfrage definitiv beenden
            </Typography>
          )}
          <ArrowForwardIosSharpIcon />
        </BottomNavigation> */}
        <BottomNav
          handleBtn={this.props.dialogHandler}
          btnParam={"true"}
          btnText={
            window.location.pathname.includes("/fr")
              ? "Terminer définitivement l'enquête"
              : window.location.pathname.includes("/it")
              ? "Concludere definitivamente il sondaggio"
              : window.location.pathname.includes("/en")
              ? "Definitely end the survey"
              : "Umfrage definitiv beenden"
          }
          backBtnState={this.props.backBtnState}
          backButtonHandler={this.props.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
