import React, { Component } from "react";
import MainPage from '../../components/Main';
import "./styles.css";

export default class Main extends Component {
    render() {
        return  (
            <div className="Main-app">
                <MainPage />
            </div>
        )
    }
}