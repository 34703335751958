import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Main from "./containers/Main";
import Register from "./containers/Register";
import ForgotPin from "./containers/ForgotPin";
// import Welcome from "./containers/Welcome";
import Home from "./containers/Home";
import Information from "./components/Information";
import FAQ from "./components/FAQ";
import Settings from "./components/Settings";
import ThankYou from "./components/ThankYou";
import Unsubscribe from "./components/Unsubscribe";
import EndSurvey from "./components/EndSurvey";
import Terms from "./components/Home/FinishJourney/TermsCondition";

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path='/' exact component={Main} />
      <Route path='/register' exact component={Register} />
      <Route path='/forgot/pin' exact component={ForgotPin} />
      {/* <Route path='/welcome' exact component={Welcome} /> */}
      <Route path='/home' exact component={Home} />
      <Route path='/terms' exact component={Terms} />
      <Route path='/de/terms' exact component={Terms} />
      <Route path='/fr/terms' exact component={Terms} />
      <Route path='/it/terms' exact component={Terms} />
      <Route path='/information' exact component={Information} />
      <Route path='/help' exact component={FAQ} />
      <Route path='/settings' exact component={Settings} />
      <Route path='/vielendank' exact component={ThankYou} />
      <Route path='/de' exact component={Main} />
      <Route path='/de/register' exact component={Register} />
      <Route path='/de/forgot/pin' exact component={ForgotPin} />
      {/* <Route path='/de/welcome' exact component={Welcome} /> */}
      <Route path='/de/home' exact component={Home} />
      <Route path='/de/information' exact component={Information} />
      <Route path='/de/help' exact component={FAQ} />
      <Route path='/de/settings' exact component={Settings} />
      <Route path='/de/vielendank' exact component={ThankYou} />
      <Route path='/de/unsubscribe' exact component={Unsubscribe} />
      <Route path='/de/notrip/' exact component={EndSurvey} />
      <Route path='/fr' exact component={Main} />
      <Route path='/fr/register' exact component={Register} />
      <Route path='/fr/forgot/pin' exact component={ForgotPin} />
      {/* <Route path='/fr/welcome' exact component={Welcome} /> */}
      <Route path='/fr/home' exact component={Home} />
      <Route path='/fr/information' exact component={Information} />
      <Route path='/fr/help' exact component={FAQ} />
      <Route path='/fr/settings' exact component={Settings} />
      <Route path='/fr/vielendank' exact component={ThankYou} />
      <Route path='/fr/unsubscribe' exact component={Unsubscribe} />
      <Route path='/fr/notrip/' exact component={EndSurvey} />
      <Route path='/it' exact component={Main} />
      <Route path='/it/register' exact component={Register} />
      <Route path='/it/forgot/pin' exact component={ForgotPin} />
      {/* <Route path='/it/welcome' exact component={Welcome} /> */}
      <Route path='/it/home' exact component={Home} />
      <Route path='/it/information' exact component={Information} />
      <Route path='/it/help' exact component={FAQ} />
      <Route path='/it/settings' exact component={Settings} />
      <Route path='/it/vielendank' exact component={ThankYou} />
      <Route path='/it/unsubscribe' exact component={Unsubscribe} />
      <Route path='/it/notrip/' exact component={EndSurvey} />
      <Route path='/en' exact component={Main} />
      <Route path='/en/register' exact component={Register} />
      <Route path='/en/forgot/pin' exact component={ForgotPin} />
      {/* <Route path='/en/welcome' exact component={Welcome} /> */}
      <Route path='/en/home' exact component={Home} />
      <Route path='/en/information' exact component={Information} />
      <Route path='/en/help' exact component={FAQ} />
      <Route path='/en/settings' exact component={Settings} />
      <Route path='/en/vielendank' exact component={ThankYou} />
      <Route path='/en/unsubscribe' exact component={Unsubscribe} />
      <Route path='/en/terms' exact component={Terms} />
      <Route path='/en/notrip/' exact component={EndSurvey} />
      <Redirect from='*' to='/' />
    </Switch>
  </BrowserRouter>
);
