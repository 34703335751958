import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import AppBar from "../../Shared/AppBar";
// import NavBar from "../../Shared/NavBar";
import PageTitle from "../../Shared/PageTitle";
import Main from "./Main";
import BottomNav from "../../Shared/BottomNav";

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "FAQ",
      back: true,
      value: "information",
    };
  }

  componentDidMount() {
    // this.isUserAuthenticated();
  }

  isUserAuthenticated = () => {
    var auth = localStorage.AccessToken;
    if (auth === undefined) {
      localStorage.clear();
      this.props.history.push("/");
    }
  };

  backButtonHandler = () => {
    if (this.state.page === "FAQ") {
      this.props.history.push("home");
    }
  };

  render() {
    // console.log('INFOS PAGE STATE: ', this.state);

    return (
      <React.Fragment>
        <AppBar state={this.state} />
        <PageTitle title={this.state.page} />
        <Main />
        {/* <NavBar state={this.state} /> */}
        <BottomNav
          btnParam={"hide"}
          backBtnState={true}
          backButtonHandler={this.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Information);
