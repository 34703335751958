import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import MenuIcon from "@material-ui/icons/Menu";
import "./styles.css";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      burgerLogic: "hideBurger",
      open: false,
    };
  }

  logOut = () => {
    localStorage.clear();
    //window.location.reload();
    this.props.history.push("goodbye");
  };

  handleChange = (event, newValue) => {
    this.props.history.push(newValue);
  };

  burgerHandler = () => {
    if (this.state.burgerLogic === "hideBurger" && !this.state.open) {
      this.setState({ burgerLogic: "showBurger", open: true });
      document.body.addEventListener("click", this.handleEventClick);
    } else {
      this.setState({ burgerLogic: "hideBurger", open: false });
    }
  };
  burgerMenuEvent = (e) => {
    this.setState({ burgerLogic: "showBurger" });
  };
  handleEventClick = (e) => {
    if (this.state.burgerLogic === "showBurger") {
      this.setState({ burgerLogic: "hideBurger" });
      document.body.removeEventListener("click", this.handleEventClick);
      // console.log("Event listener removed");
    }
  };
  //Go to main-home screen on click
  homepageClick = () => {
    if (this.props.toMainScreen) {
      this.props.toMainScreen();
    }
  };
  render() {
    return (
      <React.Fragment>
        <MenuIcon
          onClick={() => {
            this.burgerHandler();
          }}
          className='menuBurger'
        />
        <BottomNavigation
          showLabels
          value={this.props.state.value}
          onChange={this.handleChange}
          className={"navBar " + this.state.burgerLogic}
        >
          <BottomNavigationAction
            label={
              window.location.pathname.includes("/fr")
                ? "Mes trajets"
                : window.location.pathname.includes("/it")
                ? "La mia panoramica dei viaggi"
                : window.location.pathname.includes("/en")
                ? "My trips overview"
                : "Meine Reiseübersicht"
            }
            onClick={this.homepageClick}
            value='home'
          />
          <BottomNavigationAction value='information' label='FAQ' />
          <BottomNavigationAction
            value='help'
            label={
              window.location.pathname.includes("/fr")
                ? "Contact"
                : window.location.pathname.includes("/it")
                ? "Contatto"
                : window.location.pathname.includes("/en")
                ? "Contact"
                : "Kontakt"
            }
          />
          <BottomNavigationAction
            value='settings'
            label={
              window.location.pathname.includes("/fr")
                ? "Paramètres"
                : window.location.pathname.includes("/it")
                ? "Impostazioni"
                : window.location.pathname.includes("/en")
                ? "Settings"
                : "Einstellungen"
            }
          />
          <BottomNavigationAction
            value=''
            onClick={() => this.logOut()}
            label={
              window.location.pathname.includes("/fr")
                ? "Se déconnecter"
                : window.location.pathname.includes("/it")
                ? "Logout"
                : window.location.pathname.includes("/en")
                ? "Logout"
                : "Abmelden"
            }
          />
        </BottomNavigation>
      </React.Fragment>
    );
  }
}

export default withRouter(NavBar);
