import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import AppBar from "../../Shared/AppBar";
import NavBar from "../../Shared/NavBar";
import PageTitle from "../../Shared/PageTitle";
import Main from "./Main";
import Feedback from "./Feedback";
import "./styles.css";
import BottomNav from "../../Shared/BottomNav";

class FAQ extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes("/fr")
      ? (this.state = {
          page: "Contact",
          back: true,
          value: "help",
          comment: "",
          contactType: null,
          email: "",
          phone: "",
          userProfile: [],
        })
      : window.location.pathname.includes("/it")
      ? (this.state = {
          page: "Contatto",
          back: true,
          value: "help",
          comment: "",
          contactType: null,
          email: "",
          phone: "",
          userProfile: [],
        })
      : window.location.pathname.includes("/en")
      ? (this.state = {
          page: "Contact",
          back: true,
          value: "help",
          comment: "",
          contactType: null,
          email: "",
          phone: "",
          userProfile: [],
        })
      : (this.state = {
          page: "Kontakt",
          back: true,
          value: "help",
          comment: "",
          contactType: null,
          email: "",
          phone: "",
          userProfile: [],
        });
  }

  componentDidMount() {
    this.isUserAuthenticated();
    this.handleUser();
  }

  loadingScreen = () => {
    this.setState({
      loading: true,
    });
  };

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          userProfile: result.Preload,
        });
      })
      .catch((error) => console.log("error", error));
  };

  isUserAuthenticated = () => {
    var auth = localStorage.AccessToken;
    if (auth === undefined) {
      localStorage.clear();
      this.props.history.push("/");
    }
  };

  backButtonHandler = () => {
    if (
      (this.state.page === "Kontakt" ||
        this.state.page === "Contatto" ||
        this.state.page === "Contact") &&
      this.state.contactType === null
    ) {
      this.props.history.push("home");
    } else {
      this.setState({
        contactType: null,
        back: false,
      });
    }
  };

  backTrueHandler = () => {
    this.setState({
      back: true,
    });
  };

  feedbackHandler = (e) => {
    this.setState({
      contactType: e,
    });
  };

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  technicalIssue = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Contacts/TechnicalIssue`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Comment: this.state.comment,
      ContactType: this.state.contactType,
      EMail: this.state.email,
      Firstname: this.state.userProfile.Firstname,
      Lastname: this.state.userProfile.Name,
      Sex: this.state.userProfile.Sex,
      PhoneNumber: this.state.phone,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .then(this.loadingScreen)
      .then(
        setTimeout(() => {
          this.props.history.push("/");
        }, 1500)
      )
      .catch((error) => console.log("error", error));
  };

  render() {
    // console.log('FAQ PAGE STATE: ', this.state);
    return (
      <React.Fragment>
        <AppBar state={this.state} />
        <PageTitle title={this.state.page} />
        {this.state.contactType === "1" ? (
          <Feedback
            state={this.state}
            backTrueHandler={this.backTrueHandler}
            onChangeHandler={this.onChangeHandler}
            technicalIssue={this.technicalIssue}
          />
        ) : this.state.contactType === "2" ? (
          <Feedback
            state={this.state}
            backTrueHandler={this.backTrueHandler}
            onChangeHandler={this.onChangeHandler}
            technicalIssue={this.technicalIssue}
          />
        ) : (
          <Main feedbackHandler={this.feedbackHandler} />
        )}
        <NavBar state={this.state} />
        <BottomNav
          btnParam={"hide"}
          backBtnText={
            window.location.pathname.includes("/fr")
              ? "RETOUR"
              : window.location.pathname.includes("/it")
              ? "INDIETRO"
              : window.location.pathname.includes("/en")
              ? "BACK"
              : "ZURÜCK"
          }
          backBtnState={true}
          backButtonHandler={this.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(FAQ);
