import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import InfoIcon from "@material-ui/icons/Info";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import getDatesArray from "../../services/DatesInterval";
import { formatSingleDate } from "../../services/FormatDates";
import BottomNav from "../../../Shared/BottomNav";
import Popup from "../../../Shared/Popup";
// import * as moment from "moment";
import "./style.css";

const useStyles = makeStyles({
  root: {
    maxWidth: "1200px",
    margin: "0 auto",
    marginBottom: "5rem",
  },
  container: {
    background: "#f2f2f2",
  },
  table: {},

  thead: {
    display: "flex",
  },

  flex_one: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  infoIcon: {
    position: "absolute",
    top: "80px",
    right: "10px",
    color: "#2d327d",
    height: "28px",
    width: "28px",
    cursor: "pointer",
  },
  infoText: {
    padding: "1rem",
    fontSize: "1.2rem",
    backgroundColor: "#f2f2f2",
  },
  fullTripString: {
    padding: "1rem",
    backgroundColor: "#f2f2f2",
  },
});

function MultipleTrips({ state, backButtonHandler, postMultipleTrips }) {
  const classes = useStyles();
  const [datesList, setDatesList] = useState([]);
  const [popup] = useState({
    content: {
      text: window.location.pathname.includes("/fr")
        ? "Avez-vous également effectué le trajet enregistré un autre jour ou en tant que voyage retour dans la direction opposée?"
        : window.location.pathname.includes("/it")
        ? "Ha anche fatto il viaggio registrato in un altro giorno o come viaggio di ritorno nella direzione opposta?"
        : window.location.pathname.includes("/en")
        ? "Have you also undertaken the recorded journey on another day or as a return journey in the opposite direction?"
        : "Haben Sie die erfasste Reise auch an einem anderen Tag oder als Rückreise in die Gegenrichtung unternommen?",
    },
  });
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    calculateInterval();
    if (
      state.screenWidth < 620 &&
      window.localStorage.firstTimeLogin &&
      !window.localStorage.andererDialog
    ) {
      togglePopup();
    }
    // eslint-disable-next-line
  }, []);
  //Toggle popup dialog
  const togglePopup = (e) => {
    if (window.localStorage.firstTimeLogin)
      window.localStorage.setItem("andererDialog", true);
    if (e) {
      if (e.target === e.currentTarget) setDisplay(!display);
      return;
    }
    setDisplay(!display);
  };
  const calculateInterval = async () => {
    let datesArr = getDatesArray(
      new Date(state.userProfile.PeriodStart),
      new Date(state.userProfile.PeriodEnd)
    );
    // datesArr = formatDates(datesArr);
    const currentDate = formatSingleDate(state.date);
    datesArr = datesArr.map((date) => {
      const rawDate = formatSingleDate(date);
      return {
        formatedDate: rawDate,
        rawDate: date,
        to: rawDate === currentDate ? true : false,
        return: false,
      };
    });
    setDatesList(datesArr);
  };

  const handleCheckbox = (e) => {
    setDatesList(
      datesList.map((date) => {
        if (e.target.value === date.formatedDate) {
          if (e.target.name === "to") {
            return {
              formatedDate: date.formatedDate,
              rawDate: date.rawDate,
              to: !date.to,
              return: date.return,
            };
          }
          return {
            formatedDate: date.formatedDate,
            rawDate: date.rawDate,
            to: date.to,
            return: !date.return,
          };
        }
        return date;
      })
    );
  };
  const getFullTripString = () => {
    let stringJourney = ``;
    stringJourney += `(${state.startPointName} - `;
    state.ExtraStops.forEach((stop, index) => {
      if (index === state.ExtraStops.length - 1) {
        stringJourney += ` ${stop.viaStationName} -`;
        return;
      }
      stringJourney += ` ${stop.viaStationName} - `;
    });
    stringJourney += ` ${state.endPointName})`;
    return stringJourney;
  };
  //Check if theres a date selected
  const checkDates = () => {
    let check = false;
    datesList.forEach((date) => {
      if (date.to || date.return) {
        check = true;
      }
    });
    return check;
  };
  return (
    <>
      <div className={`${classes.root} screenSwitchAnimaiton`}>
        {state.screenWidth < 620 ? (
          <InfoIcon className={classes.infoIcon} onClick={togglePopup} />
        ) : (
          <div className={classes.infoText}>
            {window.location.pathname.includes("/fr")
              ? "Avez-vous également effectué le trajet enregistré un autre jour ou en tant que voyage retour dans la direction opposée?"
              : window.location.pathname.includes("/it")
              ? "Ha anche fatto il viaggio registrato in un altro giorno o come viaggio di ritorno nella direzione opposta?"
              : window.location.pathname.includes("/en")
              ? "Have you also undertaken the recorded journey on another day or as a return journey in the opposite direction?"
              : "Haben Sie die erfasste Reise auch an einem anderen Tag oder als Rückreise in die Gegenrichtung unternommen?"}
          </div>
        )}
        <Popup
          hidePopup={togglePopup}
          content={popup.content}
          display={display}
        />
        <div className={classes.fullTripString}>
          <span>{getFullTripString()}</span>
        </div>
        <TableContainer
          component={Paper}
          className={classes.container}
          elevation={1}
          variant='outlined'
          square
        >
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow className={classes.thead}>
                <TableCell className={classes.flex_one} align='left'>
                  {window.location.pathname.includes("/fr")
                    ? "Date"
                    : window.location.pathname.includes("/it")
                    ? "Data"
                    : window.location.pathname.includes("/en")
                    ? "Date"
                    : "Datum"}
                </TableCell>
                <TableCell align='right'>
                  <p>
                    {window.location.pathname.includes("/fr")
                      ? "Sortant"
                      : window.location.pathname.includes("/it")
                      ? "Uscita"
                      : window.location.pathname.includes("/en")
                      ? "Outward trip"
                      : "Hinreise"}
                  </p>
                </TableCell>
                <TableCell align='right'>
                  <p>
                    {window.location.pathname.includes("/fr")
                      ? "Retour"
                      : window.location.pathname.includes("/it")
                      ? "Ritorno"
                      : window.location.pathname.includes("/en")
                      ? "Return trip"
                      : "Rückreise"}
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datesList.map((date, index) => (
                <TableRow key={index} className={classes.thead}>
                  <TableCell className={classes.flex_one}>
                    <span>{date.formatedDate}</span>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disableRipple
                      checked={date.to}
                      value={date.formatedDate}
                      name={"to"}
                      onChange={handleCheckbox}
                    ></Checkbox>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disableRipple
                      checked={date.return}
                      name={"return"}
                      value={date.formatedDate}
                      onChange={(e) => handleCheckbox(e)}
                    ></Checkbox>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <BottomNav
        nxtBtnColor={checkDates() ? "#02b05a" : "rgb(238, 238, 238)"}
        handleBtn={checkDates() ? postMultipleTrips : null}
        nxtTxtColor={checkDates() ? "#fff" : "#666"}
        btnParam={datesList}
        backBtnState={state.back}
        backButtonHandler={backButtonHandler}
        btnText={
          window.location.pathname.includes("/fr")
            ? "Continuer"
            : window.location.pathname.includes("/it")
            ? "Avanti"
            : window.location.pathname.includes("/en")
            ? "Continue"
            : "Weiter"
        }
      />
    </>
  );
}

export default MultipleTrips;
