import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AppBar from "../../Shared/AppBar";
import axios from "axios";
import querystring from "querystring";
import ThankYou from "../../assets/icons/thankyou.png";
import "./styles.css";

class Main extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes("/fr")
      ? (this.state = {
          page: "Merci",
          back: false,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        })
      : window.location.pathname.includes("/it")
      ? (this.state = {
          page: "Grazie",
          back: false,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        })
      : window.location.pathname.includes("/en")
      ? (this.state = {
          page: "Thank you",
          back: false,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        })
      : (this.state = {
          page: "Vielen Dank",
          back: false,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        });
  }

  backButtonHandler = () => {
    if (this.state.page === "Vielen Dank") {
      this.props.history.push("/de");
    }
    if (this.state.page === "Merci") {
      this.props.history.push("/fr");
    }
    if (this.state.page === "Grazie") {
      this.props.history.push("/it");
    }
    if (this.state.page === "Thank you") {
      this.props.history.push("/en");
    }
  };

  forgotPinHandler = (event) => {
    event.preventDefault();
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/ResetPinRequest`;

    if (!event.target[0].value.includes("@")) {
      this.setState({
        dialogError: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
        fade: "in",
      });
    } else {
      axios
        .post(
          proxyUrl + targetUrl,
          querystring.stringify({
            email: event.target[0].value,
          })
        )

        .then((response) => {
          if (response.status === 200) {
            // console.log('Reset Pin Sent');
            this.setState({
              dialogSuccess: "Danke. Sie erhalten eine E-Mail mit Ihrem PIN.",
              successFade: "in",
              fade: "out",
            });
          }
          // console.log('Login Response', response);
        })
        .catch((error) => {
          console.log("There is an error: ", error.message);
          if (error.message === "Request failed with status code 400") {
            this.setState({
              dialogError:
                "Wir konnten kein Konto unter dieser E-Mail-Adresse finden, bitte überprüfen Sie Ihre Eingabe.",
              fade: "in",
            });
          } else {
            this.setState({
              dialogError: "" + error.message,
              fade: "in",
            });
          }
        });
    }
  };

  render() {
    // console.log('FORGOT PIN: ', this.state);

    return (
      <React.Fragment>
        <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
        <CssBaseline />
        <Container className='finishSection' maxWidth='false'>
          <div>
            <div className='checkMark'>
              <img src={ThankYou} alt='Vielen Dank!' />
            </div>
            {window.location.pathname.includes("/fr") ? (
              <p>
                Nous vous remercions pour votre participation et vous souhaitons
                beaucoup de plaisir dans les transports publics .
              </p>
            ) : window.location.pathname.includes("/it") ? (
              <p>
                Grazie per la sua partecipazione. Le auguriamo un ulteriore buon
                viaggio con i trasporti pubblici.
              </p>
            ) : window.location.pathname.includes("/en") ? (
              <p>
                Thank you for taking part. We hope you continue to enjoy
                travelling by public transport in the future.
              </p>
            ) : (
              <p>
                Herzlichen Dank für Ihre Teilnahme. Wir wünschen Ihnen weiterhin
                gute Fahrt mit dem öffentlichen Verkehr.
              </p>
            )}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
