import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "./styles.css";

class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <div className='_container-noborder'>
          <Container maxWidth={false} disableGutters={true}>
            <Container maxWidth={false} disableGutters={true}>
              {window.location.pathname.includes("/fr") ? (
                <Typography>
                  <p style={{ paddingLeft: "1rem" }}>
                    Vous avez des questions sur l'enquête? Nous y répondons par
                    e-mail ou par téléphone.
                  </p>
                </Typography>
              ) : window.location.pathname.includes("/it") ? (
                <Typography>
                  <p style={{ paddingLeft: "1rem" }}>
                    Ha domande sul sondaggio? Può contattarci per e-mail o per
                    telefono.
                  </p>
                </Typography>
              ) : window.location.pathname.includes("/en") ? (
                <Typography>
                  <p style={{ paddingLeft: "1rem" }}>
                    Do you have any questions about the survey? We are here for
                    you by email or phone.
                  </p>
                </Typography>
              ) : (
                <Typography>
                  <p style={{ paddingLeft: "1rem" }}>
                    Haben Sie Fragen zur Umfrage? Wir sind für Sie da per E-Mail
                    oder per Telefon.
                  </p>
                </Typography>
              )}
            </Container>
            <Container
              maxWidth={false}
              disableGutters={true}
              style={{ padding: "0 1rem" }}
            >
              {/* <Button onClick={() => this.props.feedbackHandler('1')} variant="contained" className="faqFirstButton" disableElevation size="large" fullWidth>
                  Fragen zur Fahrtenerfassung
                </Button>
                <Button onClick={() => this.props.feedbackHandler('2')} variant="contained" className="faqButton" disableElevation size="large" fullWidth>
                  Technische Störung
                </Button> */}
              {window.location.pathname.includes("/fr") ? (
                <Button
                  onClick={() =>
                    window.open("mailto:contact@allianceswisspass.ch")
                  }
                  variant='contained'
                  className='faqButton'
                  disableElevation
                  size='large'
                  fullWidth
                >
                  contact@allianceswisspass.ch
                </Button>
              ) : window.location.pathname.includes("/it") ? (
                <Button
                  onClick={() =>
                    window.open("mailto:contact@allianceswisspass.ch")
                  }
                  variant='contained'
                  className='faqButton'
                  disableElevation
                  size='large'
                  fullWidth
                >
                  contact@allianceswisspass.ch
                </Button>
              ) : window.location.pathname.includes("/en") ? (
                <Button
                  onClick={() =>
                    window.open("mailto:contact@allianceswisspass.ch")
                  }
                  variant='contained'
                  className='faqButton'
                  disableElevation
                  size='large'
                  fullWidth
                >
                  contact@allianceswisspass.ch
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    window.open("mailto:contact@allianceswisspass.ch")
                  }
                  variant='contained'
                  className='faqButton'
                  disableElevation
                  size='large'
                  fullWidth
                >
                  contact@allianceswisspass.ch
                </Button>
              )}
              {window.location.pathname.includes("/fr") ? (
                <Button
                  onClick={() => window.open("tel:+41313592250")}
                  variant='contained'
                  className='faqButton'
                  disableElevation
                  size='large'
                  fullWidth
                >
                  (+41) 031 359 22 50
                </Button>
              ) : window.location.pathname.includes("/it") ? (
                <Button
                  onClick={() => window.open("tel:+41313592250")}
                  variant='contained'
                  className='faqButton'
                  disableElevation
                  size='large'
                  fullWidth
                >
                  (+41) 031 359 22 50
                </Button>
              ) : (
                <Button
                  onClick={() => window.open("tel:+41313592250")}
                  variant='contained'
                  className='faqButton'
                  disableElevation
                  size='large'
                  fullWidth
                >
                  (+41) 031 359 22 50
                </Button>
              )}

              {window.location.pathname.includes("/fr") ? (
                <Typography>
                  <p>
                    Vous avez des questions et remarques générales sur
                    l'abonnement général?
                  </p>
                  <a
                    href='https://www.sbb.ch/fr/abonnements-et-billets/abonnements/ag/apercu-ag-sur-swisspass/questions-reponses-ag.html'
                    className='policyButton'
                    // eslint-disable-next-line
                    target='_blank'
                    disableElevation
                    size='large'
                    fullWidth
                  >
                    Questions et réponses sur l'AG
                  </a>
                </Typography>
              ) : window.location.pathname.includes("/it") ? (
                <Typography>
                  <p>
                    Ha domande generali e richieste in merito all'abbonamento
                    generale?
                  </p>
                  <a
                    href='https://www.sbb.ch/it/abbonamenti-e-biglietti/abbonamenti/ag/panoramica-ag-swisspass/domande-riposte-ag.html'
                    className='policyButton'
                    // eslint-disable-next-line
                    target='_blank'
                    disableElevation
                    size='large'
                    fullWidth
                  >
                    Domande e risposte sull'AG
                  </a>
                </Typography>
              ) : window.location.pathname.includes("/en") ? (
                <Typography>
                  <p>
                    Do you have any general questions or concerns about the GA
                    travelcard?
                  </p>
                  <a
                    href='https://www.sbb.ch/en/help-and-contact/produkte-services/travelcards/ga-and-half-fare-travelcard/ga-travelcard.html'
                    className='policyButton'
                    // eslint-disable-next-line
                    target='_blank'
                    disableElevation
                    size='large'
                    fullWidth
                  >
                    Questions and answers about the GA
                  </a>
                </Typography>
              ) : (
                <Typography>
                  <p>
                    Haben Sie generelle Fragen und Anliegen zum
                    Generalabonnement?
                  </p>
                  <a
                    href='https://www.sbb.ch/de/abos-billette/abonnemente/ga/ga-im-ueberblick/fragen-antworten-ga.html'
                    className='policyButton'
                    // eslint-disable-next-line
                    target='_blank'
                    disableElevation
                    size='large'
                    fullWidth
                  >
                    Fragen und Antworten zum GA
                  </a>
                </Typography>
              )}
            </Container>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
