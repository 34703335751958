import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import "./styles.css";
import TextField from "@material-ui/core/TextField";
import AppBar from "../../Shared/AppBar";
import Button from "@material-ui/core/Button";
import axios from "axios";
import querystring from "querystring";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Typography } from "@material-ui/core";
import "./styles.css";

class Main extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes("/fr")
      ? (this.state = {
          page: "NIP oublié?",
          back: true,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        })
      : window.location.pathname.includes("/it")
      ? (this.state = {
          page: "PIN dimenticato?",
          back: true,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        })
      : (this.state = {
          page: "PIN vergessen?",
          back: true,
          prev: "",
          dialogError: "",
          dialogSuccess: "",
          successFade: "out",
          fade: "out",
        });
  }

  backButtonHandler = () => {
    if (this.state.page === "PIN vergessen?") {
      this.props.history.push("/");
    }
    if (this.state.page === "NIP oublié?") {
      this.props.history.push("/fr");
    }
    if (this.state.page === "PIN dimenticato?") {
      this.props.history.push("/it");
    }
  };

  forgotPinHandler = (event) => {
    event.preventDefault();
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/ResetPinRequest`;

    if (!event.target[0].value.includes("@")) {
      window.location.pathname.includes("/fr")
        ? this.setState({
            dialogError: "Veuillez indiquer votre adresse e-mail.",
            fade: "in",
          })
        : window.location.pathname.includes("/it")
        ? this.setState({
            dialogError: "Immetta il suo indirizzo e-mail.",
            fade: "in",
          })
        : this.setState({
            dialogError: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
            fade: "in",
          });
    } else {
      axios
        .post(
          proxyUrl + targetUrl,
          querystring.stringify({
            email: event.target[0].value,
          })
        )

        .then((response) => {
          if (response.status === 200) {
            // console.log('Reset Pin Sent');
            window.location.pathname.includes("/fr")
              ? this.setState({
                  dialogSuccess:
                    "Merci. Vous allez recevoir un e-mail contenant votre NIP.",
                  successFade: "in",
                  fade: "out",
                })
              : window.location.pathname.includes("/it")
              ? this.setState({
                  dialogSuccess: "Grazie. Riceverà un'e-mail con il suo PIN.",
                  successFade: "in",
                  fade: "out",
                })
              : this.setState({
                  dialogSuccess:
                    "Danke. Sie erhalten eine E-Mail mit Ihrem PIN.",
                  successFade: "in",
                  fade: "out",
                });
          }
          // console.log('Login Response', response);
        })
        .catch((error) => {
          console.log("There is an error: ", error.message);
          if (error.message === "Request failed with status code 400") {
            window.location.pathname.includes("/fr")
              ? this.setState({
                  dialogError:
                    "Aucun profil trouvé pour cette adresse e-mail, veuillez vérifier vos indications.",
                  fade: "in",
                })
              : window.location.pathname.includes("/it")
              ? this.setState({
                  dialogError:
                    "Il tempo di immissione di questo PIN è già scaduto. Per motivi di elaborazione non è più consentita alcuna registrazione dei dati. ",
                  fade: "in",
                })
              : this.setState({
                  dialogError:
                    "Wir konnten kein Profil unter dieser E-Mail-Adresse finden, bitte überprüfen Sie Ihre Eingabe.",
                  fade: "in",
                });
          } else {
            this.setState({
              dialogError: "" + error.message,
              fade: "in",
            });
          }
        });
    }
  };

  render() {
    // console.log('FORGOT PIN: ', this.state);

    return (
      <React.Fragment>
        <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
        <CssBaseline />
        <Container maxWidth='false' className='loginScreen'>
          <Card raised='true' className={"errorDialog fade-" + this.state.fade}>
            <CardContent className='errorContent'>
              <Typography align='center'>{this.state.dialogError}</Typography>
            </CardContent>
          </Card>
          <Card
            raised='true'
            className={"successDialog fade-" + this.state.successFade}
          >
            <CardContent className='errorContent'>
              <Typography align='center'>{this.state.dialogSuccess}</Typography>
            </CardContent>
            <CardActions disableSpacing='true' className='successActionArea'>
              <Button
                onClick={() => this.props.history.push("/")}
                size='medium'
                className='backToLoginButton'
                fullWidth
              >
                Login
              </Button>
            </CardActions>
          </Card>
          <div className='loginFields'>
            {window.location.pathname.includes("/fr") ? (
              <p>Veuillez indiquer votre adresse e-mail.</p>
            ) : window.location.pathname.includes("/fr") ? (
              <p>Immetta il suo indirizzo e-mail.</p>
            ) : (
              <p>Bitte geben Sie Ihre E-Mail-Adresse ein.</p>
            )}
            <form onSubmit={this.forgotPinHandler} noValidate autoComplete>
              <TextField
                fullWidth='true'
                id='email'
                name='email'
                label='E-Mail'
                variant='filled'
              />
              <Container className='forgotButtonSection'>
                {window.location.pathname.includes("/fr") ? (
                  <Button
                    type='submit'
                    className='forgotButton'
                    variant='contained'
                  >
                    Envoyer
                  </Button>
                ) : window.location.pathname.includes("/fr") ? (
                  <Button
                    type='submit'
                    className='forgotButton'
                    variant='contained'
                  >
                    Inviare
                  </Button>
                ) : (
                  <Button
                    type='submit'
                    className='forgotButton'
                    variant='contained'
                  >
                    Senden
                  </Button>
                )}
              </Container>
            </form>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
