import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import "./styles.css";
import { Toolbar } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';

class Feedback extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailChecked: null,
      phoneChecked: null
    }
  }

  componentDidMount() {
    this.props.backTrueHandler();
  }

  handleChangeEmail = (event) => {
    this.setState({
      emailChecked: (event.target.checked),
      phoneChecked: (!event.target.checked)
    })
  }

  handleChangePhone = (event) => {
    this.setState({
      emailChecked: (!event.target.checked),
      phoneChecked: (event.target.checked)
    })
  }

  render() {
    // console.log('HELP SCREEN STATE', this.state);
    
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" disableGutters="true">
        </Container>
        <Container className="feedbackInputSection">
          <Typography className="feedbackTextLabel">
            Erläutern Sie bitte kurz Ihr Anliegen (Hinweis: hier bitte keine Fahrten erfassen)
          </Typography>
          <TextField required name='comment' onChange={this.props.onChangeHandler} className="feedbackTextArea" fullWidth multiline id="filled-basic" variant="filled" rows="6" rowsMax="6" type="text" />
          <Typography className="feedbackBodyText" >
            Wie möchten Sie am liebsten kontaktiert werden?
          </Typography>
          <Toolbar className="checkBoxToolBar">
            {
              // eslint-disable-next-line
              <Checkbox onChange={this.props.onChangeHandler} checked={this.state.emailChecked} value='2' name='contactType' onChange={this.handleChangeEmail} color="primary" inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
            }
            <Typography className="toolBarLabel">
              per E-Mail
            </Typography>
          </Toolbar>
          <Toolbar className="checkBoxToolBar">
            {
              // eslint-disable-next-line
              <Checkbox onChange={this.props.onChangeHandler} checked={this.state.phoneChecked} value='1' name='contactType' onChange={this.handleChangePhone} color="primary" inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
            }
            <Typography className="toolBarLabel">
              per Telefon
            </Typography>
          </Toolbar>
          {
            this.state.emailChecked === true ?
              <TextField className="feedbackTextArea" onChange={this.props.onChangeHandler} value={this.props.state.email} name='email' fullWidth label="Bitte geben Sie Ihre E-Mail-Adresse an." id="filled-basic" variant="filled" />
            : this.state.phoneChecked === true ?
              <TextField className="feedbackTextArea" onChange={this.props.onChangeHandler} value={this.props.state.phone} name='phone' fullWidth label="Bitte geben Sie Ihre Telefonummer an." id="filled-basic" variant="filled" />
            :null
          }
          <Button type="submit" onClick={() => this.props.technicalIssue()} className="feedbackSendButton" disableElevation variant="contained">Absenden</Button>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Feedback);